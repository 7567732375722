export default {
  normal: {
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "common_dialog" */ "@/mods/common/normal/selectDialogTree"
      ),
  },
  input: {
    title: "actions.edit",
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "common_dialog" */ "@/mods/common/formDialog/QuickInputDialog"
      ),
  },
  help: {
    introduction: {
      title: "功能介绍",
      width: "m",
      footer: null,
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/help/FunctionIntroductionDialog"
        ),
    },
  },
  attachment: {
    preview: {
      title: "actions.preview",
      width: "l",
      footer: null,
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/attachment/AttachmentPreviewDialog"
        ),
    },
  },
  report: {
    chooser: {
      width: "m",
      title: "选择打印报表",
      okText: "actions.print",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/report/PrintReportChooser"
        ),
    },
    select: {
      width: "m",
      title: "选择打印报表",
      okText: "打印",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/report/printReport/PrintReportSelect.vue"
        ),
    },
  },
  user: {
    chooser: {
      width: "l",
      title: "user.choose.text",
      okText: "user.choose.yes",
      cancelText: "user.choose.no",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/user/UserChooser"
        ),
    },
    notificationList: {
      title: "user.notification",
      width: "m",
      footer: null,
      component: () =>
        import(
          /* webpackChunkName: "common_list" */ "@/mods/common/notification/NotificationList"
        ),
    },
    messageList: {
      title: "user.message.mine",
      width: "m",
      footer: null,
      component: () =>
        import(
          /* webpackChunkName: "common_list" */ "@/mods/common/message/MessageList"
        ),
    },
    todoList: {
      title: "user.schedule.mine",
      width: "m",
      footer: null,
      component: () =>
        import(
          /* webpackChunkName: "common_list" */ "@/mods/common/todo/TodoList"
        ),
    },
    profile: {
      width: "s",
      title: "user.profile",
      okText: "actions.save",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/user/UserProfile"
        ),
    },
    resetPassword: {
      width: "s",
      title: "user.password.reset",
      okText: "actions.save",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/user/ResetPassword"
        ),
    },
    setting: {
      width: "s",
      title: "user.setting",
      okText: "actions.save",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/user/PersonalSetting"
        ),
    },
    chooseUser: {
      width: "860px",
      title: "选择人员",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/user/chooseUser/ChooseUser.vue"
        ),
    },
  },
  flow: {
    actionChooser: {
      width: "s",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/flow/FlowActionChooser"
        ),
    },
    audit: {
      width: "s",
      title: "提交审批",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/flow/flowAudit/FlowAudit"
        ),
    },
  },
  archive: {
    select: {
      width: "1600px",
      title: "选择档案",
      okText: "选择所选档案",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/archive/select/ArchiveSelected"
        ),
    },
    condition: {
      width: "m",
      title: "设置条件",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/archive/select/dialog/PortfolioCondition.vue"
        ),
    },
    archRecord: {
      width: "xl",
      title: "文件信息",
      destroyOnClose: true,
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/archive/record/ArchRecord"
        ),
    },
    mainTableDownloadEFile: {
      width: "s",
      title: "下载文件",
      destroyOnClose: true,
      component: () =>
        import(
          /* webpackChunkName: "archive_dialog" */ "@/mods/common/archive/record/dialog/MainTableDownloadEFile"
        ),
    },
    eFileEdit: {
      width: "s",
      title: "编辑文件信息",
      destroyOnClose: true,
      component: () =>
        import(
          /* webpackChunkName: "archive_dialog" */ "@/mods/common/archive/record/dialog/EFileEdit"
        ),
    },
    metaRelate: {
      width: "s",
      title: "添加元数据关联",
      destroyOnClose: true,
      component: () =>
        import(
          /* webpackChunkName: "archive_dialog" */ "@/mods/common/archive/record/dialog/MetaRelate"
        ),
    },
    uploadFilesMiddleDialog: {
      width: "l",
      title: "挂接电子文件",
      destroyOnClose: true,
      footer: null,
      component: () =>
        import(
          /* webpackChunkName: "archive_dialog" */ "@/mods/common/archive/record/dialog/UploadFilesMiddleDialog.vue"
        ),
    },
    verifySave: {
      width: "s",
      title: "是否保存条目信息",
      destroyOnClose: true,
      component: () =>
        import(
          /* webpackChunkName: "archive_dialog" */ "@/mods/common/archive/record/dialog/VerifySave"
        ),
    },
  },
  condition: {
    setConditions: {
      width: "m",
      title: "设置条件",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/condition/SetConditions"
        ),
    },
  },
  viewAttachList: {
    width: "xl",
    footer: null,
    title: "电子文件浏览",
    component: () =>
      import(
        /* webpackChunkName: "demo_dialog" */ "@/mods/common/normal/ViewAttach"
      ),
  },
  quickAdd: {
    width: "xxl",
    title: "档案列表",
    component: () =>
      import(
        /* webpackChunkName: "common_dialog" */ "@/mods/common/normal/QuickAddArchives"
      ),
  },
  fieldsSetting: {
    width: "l",
    component: () =>
      import(
        /* webpackChunkName: "common_dialog" */ "@/mods/common/filedsSetting/ExportFieldsSetting"
      ),
  },
  ftpChooser: {
    width: "l",
    component: () =>
      import(
        /* webpackChunkName: "common_dialog" */ "@/mods/common/normal/ftpChooser/FtpChooser"
      ),
  },
  role: {
    selectRole: {
      width: "s",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/role/SelectRole"
        ),
    },
  },
  eleFile: {
    metaData: {
      width: "l",
      title: "元数据",
      footer: null,
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/eleFile/EleFileMetaData.vue"
        ),
    },
  },
  project: {
    select: {
      width: "m",
      title: "选择项目",
      component: () =>
        import(
          /* webpackChunkName: "common_dialog" */ "@/mods/common/project/handleProjectSelect.vue"
        ),
    },
  },
};
