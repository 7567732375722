export default {
  setDepartmentAuth: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/projectManage/dialog/SetDepartmentAuthDialog"
      ),
  },
  setArchAuth: {
    width: "s",
    title: "授权门类",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/projectManage/dialog/SetArchAuthDialog"
      ),
  },
};
