<template>
  <div class="detail-content-view">
    <div class="detail-content-view-header">
      <div class="detail-content-view-header-left" @click="onBack">
        <left-outlined class="detail-content-view-icon" />
        <div>{{ $t("actions.back") }}</div>
      </div>
      <div class="detail-content-view-title">{{ displayTitle }}</div>
      <grouped-toolbar
        class="detail-content-view-toolbar"
        :operations="operations"
        @action="onToolAction"
      ></grouped-toolbar>
    </div>
    <template v-if="$slots.default">
      <slot></slot>
    </template>
    <div v-else-if="view" class="detail-content-view-content">
      <suspense @resolve="onLoaded">
        <component
          :is="componentDef"
          :key="compKey"
          ref="contentRef"
          v-bind="params"
        ></component>
      </suspense>
    </div>
  </div>
</template>

<script>
import {
  toRefs,
  computed,
  reactive,
  provide,
  ref,
  inject,
  nextTick,
} from "vue";
import { i18n } from "@/conf/lang";
import detailViewConf from "@/conf/detailView";
import { useAsyncCompDef } from "@/components/common/shared/asyncCompInternal";
import { DETAIL_VIEW } from "@/conf/symbols";
import { DETAIL_VIEW_PROVIDER } from "@/components/common/shared/internal";
import { LeftOutlined } from "@ant-design/icons-vue";
import GroupedToolbar from "@/components/common/action/GroupedToolbar";

const cached = {};
export default {
  name: "DetailContentView",
  components: { GroupedToolbar, LeftOutlined },
  emits: ["back"],
  props: {
    view: { type: [String, Function], default: "" },
    params: { type: Object, default: () => ({}) },
    title: { type: String, default: "" },
  },
  setup(props, { slots, emit }) {
    const { view, title, params } = toRefs(props);
    const contentRef = ref(null);
    const provider = inject(DETAIL_VIEW_PROVIDER, null);
    let componentDef, curConfig;
    if (slots.default) {
      curConfig = null;
    } else {
      const comp = useAsyncCompDef(view.value, cached, detailViewConf);
      curConfig = comp.config;
      componentDef = comp.componentDef;
    }
    const refProps = { title };
    const operations = ref([]);
    const dataRef = ref(null);
    const compKey = view.value;
    const cachedData = reactive({});
    const generateLocaleCachedProp = (name) =>
      computed(() => {
        if (cachedData[name]) return cachedData[name];
        if (refProps[name].value) return refProps[name].value;
        const value = curConfig?.[name];
        if (typeof value === "string") {
          return i18n.global.t(value);
        }
        return value;
      });
    const displayTitle = generateLocaleCachedProp("title");
    const handler = {
      setOperations: (arr) => (operations.value = arr),
      setTitle: (title) => (cachedData.title = title),
      setFinished: (data) => (dataRef.value = data),
      navigateTo: (name, params) => provider.navigateTo(name, params),
      back: () => onBack(),
    };
    const onToolAction = (e) => {
      contentRef.value?.onViewToolAction?.(e);
    };
    const onBack = () => emit("back", dataRef.value);
    const onLoaded = () => {
      nextTick(() => {
        contentRef.value?.onViewShowed?.({ handler, params: params.value });
      });
    };
    if (!slots.default) {
      provide(DETAIL_VIEW, handler);
    }
    return {
      contentRef,
      componentDef,
      compKey,
      displayTitle,
      operations,
      onBack,
      onToolAction,
      onLoaded,
    };
  },
};
</script>

<style scoped>
.detail-content-view {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f6f7fb;
  z-index: 5;
}
.detail-content-view-header {
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  background: white;
  box-shadow: var(--rmx-header-shadow);
  padding: 0 24px;
  flex: 0 0 auto;
}
.detail-content-view-title {
  font-weight: bold;
  font-size: 16px;
  color: var(--rmx-text-1);
  margin-right: 16px;
}
.detail-content-view-header-left {
  color: var(--rmx-text-2);
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.detail-content-view-icon {
  font-size: 16px;
  margin-right: 4px;
}
.detail-content-view-header-left:hover {
  color: var(--rmx-primary-color);
}
.detail-content-view-toolbar {
  flex: 1;
  justify-content: flex-end;
}
.detail-content-view-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 16px;
  height: 1px;
  background: white;
  box-shadow: var(--rmx-main-shadow);
  border-radius: var(--rmx-border-radius);
}
</style>
