import { http } from "@/util/http";
import { getFileUrl, httpDownload } from "@/util/fileUtil";
import { searchListMapper } from "@/mods/front/util/frontUtil";
import { HTTP_METHODS } from "@/conf/constants";

function computeDisabled(item, data) {
  let collectionDisabled = false;
  let borrowCartDisabled = false;
  if (+item.isshow === 0) {
    collectionDisabled = true;
    borrowCartDisabled = true;
  }
  if (+item.filesnum === 0 && +item.fillingnum === 0) {
    borrowCartDisabled = true;
  }
  return {
    ...searchListMapper(item, data),
    collectionDisabled,
    borrowCartDisabled,
  };
}

export function httpCurrentDetail({
  dataId,
  archTypeId,
  objectType,
  source,
  orderId,
  readOnly,
  tableId,
}) {
  return http
    .post("/currency/detail", {
      dataid: dataId,
      archTypeId,
      objectType,
      source,
      orderId,
      readOnly,
      tableId,
    })
    .then((res) => {
      const listParams = res.data || {};
      const detail = computeDisabled(listParams?.data || {});
      return {
        allList: {
          ...listParams,
          showField: listParams.showfield,
        },
        list: {
          ...detail,
          borrowCart: detail.borrowcart,
          borrowCartId: detail.borrowcartid,
          shopCart: detail.shopcart,
          shopCartId: detail.shopcartid,
          downloadSource: detail.downloadsource,
          onlinePrint: detail.onlineprint,
          recordScan: detail.recordscan,
          isVolDoc: detail.isvoldoc,
          physicalType: detail.physicaltype,
          eType: detail.etype,
          useScope: +detail.usescope,
          isSecurity: !!+detail.securityCode,
          securityText:
            detail.securityText || detail.securityname || detail.security,
          scopeType: detail.companyattr,
          disabledVolDetail: detail.disabledvoldetail,
        },
      };
    });
}
// 电子文件
export function httpFileList({
  dataId,
  archTypeId,
  objectType,
  source,
  readOnly,
  tableId,
  orderId,
}) {
  return http
    .post("/home/favorite/file/list", {
      dataid: dataId,
      archTypeId,
      objectType,
      source,
      readOnly,
      tableId,
      orderId,
    })
    .then((res) => {
      return {
        list: (res.data.list || []).map((x) => ({
          ...x,
          url: getFileUrl(x.url),
        })),
      };
    });
}
// 卷内文件
export function httpVolumeDoc({
  dataId,
  archTypeId,
  objectType,
  source,
  readOnly,
  tableId,
}) {
  return http
    .post("/currency/volofdoc", {
      dataid: dataId,
      archTypeId,
      objectType,
      source,
      readOnly,
      tableId,
    })
    .then((res) => {
      const listInfo = res.data.subvollist.map((x) => {
        return x.data;
      });
      const extra = { archTypeId };
      return {
        list: (listInfo || []).map((x) => computeDisabled(x, extra)),
        total: +res.data.total || +res.count,
        fields: res.data.showFieldList,
      };
    });
}
// 关联档案
export function httpRelationList({
  dataId,
  archTypeId,
  objectType,
  source,
  readOnly,
  tableId,
}) {
  return http.post("/currency/relationlist", {
    dataid: dataId,
    archTypeId,
    objectType,
    source,
    readOnly,
    tableId,
  });
}
function commonReqParam(data) {
  let dataInfo = {};
  if (Array.isArray(data)) {
    let ids = data
      .filter((item) => !item.isDisabled)
      .map((x) => {
        return x.id;
      });
    dataInfo = {
      archtypeid: data[0].archTypeId,
      documentOrVolumeId: data[0].archId,
      orderId: data[0].orderId,
      downloadType: "11",
      fondsid: data[0].fondsid,
      ids: ids,
      objectType: 3,
      type: "21",
      typeList: data[0].typeList,
    };
  } else {
    dataInfo = {
      archtypeid: data.archTypeId,
      documentOrVolumeId: data.archId,
      downloadType: "4",
      fondsid: data.fondsid,
      ids: [data.id],
      objectType: 3,
      typeList: data.typeList,
      orderId: data.orderId,
    };
  }
  return { dataInfo };
}
//下载校验
export function httpFileDownloadVerify(data) {
  const { dataInfo } = commonReqParam(data);
  return http.post("/home/common/record/verify/download", dataInfo);
}
// 下载接口
export function httpFileDownload(data) {
  const { dataInfo } = commonReqParam(data);
  return httpDownload(
    "/home/common/record/download",
    { param: JSON.stringify(dataInfo) },
    "",
    HTTP_METHODS.GET
  );
}
// 打印
export function httpPrintBrowse({ id, archId, objectType, archTypeId }) {
  const url = getFileUrl("/home/common/record/browse", {
    id,
    documentOrVolumeId: archId,
    objectType,
    type: 10,
    usingtype: 2,
    attachTable: "e_record",
    archtypeid: archTypeId,
    isfront: 1,
  });
  window.open(url, "_blank");
}
// 下载类型
export function httpDownloadType({
  archId,
  archTypeId,
  objectType,
  id,
  download,
  downloadSource,
}) {
  return http.post("/home/common/record/download/type", {
    archId,
    archTypeId,
    objectType,
    erecordid: id,
    download,
    downloadsource: downloadSource,
  });
}
// 保存摘录
export function httpExcerptSave({ archId, archTypeId, excerptContent }) {
  return http.post("home/excerptinfo/save", {
    archid: archId,
    archtypeid: archTypeId,
    excerptcontent: excerptContent,
  });
}
