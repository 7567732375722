<template>
  <div class="rich-text-editor rmx-rich-text-editor">
    <toolbar
      class="rich-text-editor-toolbar"
      :editor="editorRef"
      :default-config="toolbarConfig"
      :mode="editorMode"
    />
    <editor
      class="rich-text-editor-context"
      v-model="html"
      :default-config="editorConfig"
      :mode="editorMode"
      :style="editorStyle"
      @on-created="handleCreated"
    />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, shallowRef, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { getFileUrlFromRelative, uploadFile } from "@/util/fileUtil";
import { useCachedProps } from "@/components/common/shared/compInternal";
import { DEFAULT_UPLOAD_URL } from "@/conf/constants";
import {
  PROPS_INPUT_BASE,
  PROPS_UPLOAD_PARAMS,
} from "@/components/common/shared/compAttrs";
import { i18nChangeLanguage } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";

const TOOL_KEYS = [
  "headerSelect",
  "|",
  "bold",
  "underline",
  "italic",
  "through",
  "clearStyle",
  "color",
  "bgColor",
  "|",
  "fontSize",
  "fontFamily",
  "lineHeight",
  "|",
  "bulletedList",
  "numberedList",
  "|",
  "justifyLeft",
  "justifyCenter",
  "justifyRight",
  "justifyJustify",
  "indent",
  "delIndent",
  "|",
  "uploadImage",
  "|",
  "undo",
  "redo",
  "|",
  "fullScreen",
];

const UPLOAD_URL = getFileUrlFromRelative(DEFAULT_UPLOAD_URL);
const BASE64_SIZE = 256 * 1024;

export default {
  name: "RichTextEditor",
  props: {
    ...PROPS_INPUT_BASE,
    ...PROPS_UPLOAD_PARAMS,
  },
  emits: ["update:value"],
  components: { Editor, Toolbar },
  setup(props, { emit }) {
    const store = useStore();
    const propRefs = toRefs(props);
    const editorRef = shallowRef();
    const { makeValProp } = useCachedProps(propRefs, { emit });
    const html = makeValProp("value", "", true);
    const editorMode = makeValProp("mode", "default");
    const toolbarConfig = computed(() => {
      return {
        toolbarKeys: TOOL_KEYS,
      };
    });
    const editorConfig = {
      placeholder: propRefs.placeholder.value,
      readOnly: propRefs.readonly.value || propRefs.disabled.value,
      MENU_CONF: {
        uploadImage: {
          fieldName: "file",
          server: UPLOAD_URL,
          base64LimitSize: BASE64_SIZE,
          customUpload(file, insert) {
            return uploadFile({
              file,
              data: {
                ...propRefs.uploadParam.value,
                type: propRefs.uploadType.value || "rich_text",
                businessId: propRefs.uploadBusinessId.value,
              },
              onProgress: ({ progress }) => {
                editorRef.value?.showProgressBar?.(progress);
              },
            }).then((data) => {
              const { previewUrl, url } = data;
              insert(getFileUrlFromRelative(previewUrl || url, true));
              return Promise.resolve();
            });
          },
        },
      },
    };
    const editorStyle = computed(() => {
      return {
        height: propRefs.height.value || "500px",
        overflowY: "hidden",
      };
    });
    if (store.state.language !== "zh-cn") {
      i18nChangeLanguage("en");
    } else {
      i18nChangeLanguage("zh-cn");
    }
    onBeforeUnmount(() => {
      const e = editorRef.value;
      if (e === null) return;
      e.destroy();
    });
    const handleCreated = (editor) => {
      editorRef.value = editor;
    };
    watch(
      () => propRefs.readonly.value || propRefs.disabled.value,
      (val) => {
        const e = editorRef.value;
        if (e) {
          if (val) e.disable();
          else e.enable();
        }
      }
    );
    watch(
      () => propRefs.placeholder.value,
      (val) => {
        const e = editorRef.value;
        if (!e) return;
        const el = e.getEditableContainer();
        const elPH = el?.querySelector?.(".w-e-text-placeholder");
        if (!elPH) return;
        elPH.innerText = val;
      }
    );
    return {
      editorRef,
      editorConfig,
      toolbarConfig,
      editorStyle,
      html,
      editorMode,
      handleCreated,
    };
  },
};
</script>

<style scoped>
.rich-text-editor {
  border: 1px solid var(--rmx-border);
  background: white;
  border-radius: var(--rmx-border-radius);
  transition: all 0.3s, height 0s;
  --w-e-textarea-slight-color: var(--rmx-text-3);
  --w-e-toolbar-active-bg-color: var(--rmx-primary-color-1);
}
.rich-text-editor.w-e-full-screen-container {
  z-index: 100;
}
.rich-text-editor:focus-within {
  border-color: var(--rmx-primary-color-4);
  box-shadow: 0 0 0 2px var(--rmx-primary-shadow);
}
.rich-text-editor-toolbar {
  border-bottom: 1px solid var(--rmx-border);
}
.rich-text-editor-context {
  overflow-y: hidden;
}
:deep(.w-e-image-container:not(.w-e-selected-image-container) img) {
  vertical-align: baseline;
}
.rich-text-editor :deep(.w-e-bar-item button) {
  border-radius: 4px;
}
</style>
