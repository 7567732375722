<template>
  <span
    v-if="dot"
    class="count-tag-dot"
    :class="{ 'count-tag-dot-show': showTag }"
  ></span>
  <template v-else>
    <span
      v-if="showTag"
      class="count-tag"
      :class="{ 'count-tag-bubble': bubble, 'count-tag-brace': brace }"
    >
      {{ count }}
    </span>
  </template>
</template>

<script>
export default {
  name: "CountTag",
  props: {
    count: { type: Number, default: 0 },
    bubble: { type: Boolean },
    brace: { type: Boolean },
    showZero: { type: Boolean },
    dot: { type: Boolean },
  },
  computed: {
    showTag() {
      return this.count || (this.count === 0 && this.showZero);
    },
  },
};
</script>

<style scoped>
.count-tag {
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  margin: 0 8px;
  white-space: nowrap;
}
.count-tag.count-tag-brace::before {
  content: "(";
}
.count-tag.count-tag-brace::after {
  content: ")";
}
.count-tag.count-tag-bubble {
  background: #ff4d4f;
  box-shadow: 0 0 0 1px #fff;
  color: white;
  width: 20px;
  height: 20px;
  font-weight: 400;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
}
.count-tag-dot {
  position: relative;
  height: 1em;
}
.count-tag-dot:after {
  content: "";
  display: block;
  position: absolute;
  background: #ff4d4f;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  top: -4px;
  left: -2px;
  transform: scale(0);
  transition: transform 0.3s;
  box-shadow: 0 0 0 1px #fff;
}
.count-tag-dot-show:after {
  transform: scale(1);
}
</style>
