import BaseLayout from "@/components/core/BaseLayout";
import ArchiveWarp from "@/mods/archive/researchManageArchive/ArchiveWarp.vue";

export default [
  {
    path: "/archive",
    name: "Archive",
    component: BaseLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "destruction_manage",
        meta: {
          title: "销毁管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/archDestructionManage/ArchDestructionManage"
          ),
      },
      {
        path: "assistance_query",
        meta: {
          title: "协助查询",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/assistanceQuery/AssistanceQuery"
          ),
      },
      {
        path: "participation_appraisal",
        meta: {
          title: "参与鉴定",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/participationAppraisal/ParticipationAppraisal"
          ),
      },
      {
        path: "archival_retentionperiod",
        meta: {
          title: "保管期限鉴定",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/archivalAppraisal/ArchivalRetentionperiod"
          ),
      },
      {
        path: "archival_security",
        meta: {
          title: "密级鉴定",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/archivalAppraisal/ArchivalSecurity"
          ),
      },
      {
        path: "archival_qxjb",
        meta: {
          title: "开放鉴定",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/archivalAppraisal/ArchivalQxjb"
          ),
      },
      {
        path: "online_receive",
        meta: {
          title: "在线接收",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/onlineReceive/OnlineReceive"
          ),
      },
      {
        path: "data_application",
        meta: { title: "数据申请" },
        component: () =>
          import("@/mods/archive/dataApplication/DataApplication"),
      },
      {
        path: "data_review",
        meta: { title: "数据审核" },
        component: () => import("@/mods/archive/dataReview/DataReview"),
      },
      {
        path: "arch_transfer",
        name: "ArchTransfer",
        meta: {
          title: "档案流转",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/archTransfer/ArchTransfer"
          ),
      },
      {
        path: "fonds_inner_transfer",
        name: "FondsInnerTransfer",
        meta: {
          title: "对内移交",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/fondsInnerTransfer/FondsInnerTransfer"
          ),
      },
      {
        path: "external_archive",
        meta: { title: "对外移交" },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/externalArchive/ExternalArchive"
          ),
      },
      {
        path: "project_manage",
        meta: { title: "项目管理" },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/projectManage/ProjectManage"
          ),
      },
      {
        path: "migration_archive_data",
        meta: {
          title: "数据迁移",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/migrationArchiveData/MigrationArchiveData"
          ),
      },
      {
        path: "push_manage",
        meta: {
          title: "推送管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/pushManage/PushManage"
          ),
      },
      {
        path: "using_appraise",
        meta: {
          title: "评价管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/usingAppraise/UsingAppraise"
          ),
      },
      {
        path: "collect",
        meta: {
          title: "接收库",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/archiveTreasury/ArchTreasury"
          ),
      },
      {
        path: "arrange",
        meta: {
          title: "整理库",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/archiveTreasury/ArchTreasury"
          ),
      },
      {
        path: "preserve",
        meta: {
          title: "保管库",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/archiveTreasury/ArchTreasury"
          ),
      },
      {
        path: "utiliz",
        meta: {
          title: "利用库",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/archiveTreasury/ArchTreasury"
          ),
      },
      {
        path: "quick_check",
        meta: { title: "快速检查" },
        component: () => import("@/mods/archive/quickCheck/QuickCheckManage"),
      },
      {
        path: "receive_offline_archive",
        meta: {
          title: "离线接收",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/receiveOfflineArchive/ReceiveOfflineArchive"
          ),
      },
      {
        path: "out_apply",
        meta: {
          title: "外单位申请",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/outApply/OutApply"
          ),
      },
      {
        path: "research_manage_archive",
        meta: {
          title: "编研管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/researchManageArchive/ResearchManageArchive"
          ),
      },
      {
        path: "topic_library",
        meta: {
          title: "专题库",
        },
        component: () =>
          import(
            /* webpackChunkName: "archive" */ "@/mods/archive/topicLibrary/TopicLibrary"
          ),
      },
    ],
  },
  {
    path: "/archiveDetail",
    name: "ArchiveDetail",
    component: ArchiveWarp,
    meta: {
      requireAuth: true,
    },
  },
];
