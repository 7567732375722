/*
 * @Author: HuaZhiQiang
 * @Date: 2022-09-27 09:20:40
 * @LastEditors: HuaZhiQiang
 * @LastEditTime: 2022-09-27 16:07:16
 * @Description: file content
 */
import BaseLayout from "@/components/core/BaseLayout";

export default [
  {
    path: "/setting",
    name: "Setting",
    component: BaseLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "management_process_flow",
        meta: {
          title: "流程管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/setting/manageProcessFlow/ManageProcessFlow"
          ),
      },
      {
        path: "fonds_manage",
        name: "FondsManage",
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/setting/fondsManage/FondsManage"
          ),
      },
      {
        path: "catalog_manage",
        name: "CatalogManage",
        meta: {
          title: "目录配置",
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/setting/catalogConfig/CatalogConfig"
          ),
      },

      {
        path: "archive_setting",
        name: "archiveSetting",
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/setting/archive/ArchiveTypeSetting"
          ),
      },
      {
        path: "distribution_setting",
        meta: {
          title: "分发配置",
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/setting/distributionSetting/DistributionSetting"
          ),
      },
      {
        path: "appraisal_plan",
        meta: {
          title: "鉴定计划",
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/setting/appraisalPlan/AppraisalPlan"
          ),
      },
      {
        path: "watermark_setting",
        name: "waterMarkSetting",
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/setting/waterMarkSetting/WaterMarkSetting"
          ),
      },
      {
        path: "report_manage",
        meta: {
          title: "报表管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/setting/reportManage/ReportManage"
          ),
      },
      {
        path: "warehouse_manage",
        meta: {
          title: "库房配置",
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/setting/warehouseManage/WarehouseManage"
          ),
      },
      {
        path: "detection_scheme",
        meta: {
          title: "检测方案",
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/setting/detectionScheme/DetectionScheme"
          ),
      },
    ],
  },
];
