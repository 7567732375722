<template>
  <popover-view
    v-if="showAccessible"
    placement="bottomLeft"
    v-model:visible="popVisible"
  >
    <div class="front-unit-chooser">
      <front-unit-chooser-option
        :option="currentOptions"
        :is-mine="currentIsMine"
        show
      />
      <down-outlined class="front-unit-chooser-down" />
    </div>
    <template #content>
      <div class="front-unit-chooser-pop">
        <div class="front-unit-chooser-group">
          <div class="front-unit-chooser-group-title">
            {{ $t("hint.my", [$t("fields.unit")]) }}
          </div>
          <div class="front-unit-chooser-group-body">
            <template v-for="it in options.mine" :key="it.value">
              <front-unit-chooser-option
                :option="it"
                :is-current="currentOptions === it"
                is-mine
                class="front-unit-chooser-group-option"
                @click="onItemClick(it)"
              />
            </template>
          </div>
        </div>
        <div class="front-unit-chooser-group">
          <div class="front-unit-chooser-group-title">
            {{ $t("user.accessible_unit") }}
          </div>
          <div class="front-unit-chooser-group-body">
            <template v-for="it in options.accessible" :key="it.value">
              <front-unit-chooser-option
                :option="it"
                :is-current="currentOptions === it"
                class="front-unit-chooser-group-option"
                @click="onItemClick(it)"
              />
            </template>
          </div>
        </div>
      </div>
    </template>
  </popover-view>
  <div v-else class="front-unit-chooser">
    <front-unit-chooser-option
      :option="currentOptions"
      :is-mine="currentIsMine"
      show
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useFrontUnit } from "@/components/front/frontNav";
import { DownOutlined } from "@ant-design/icons-vue";
import PopoverView from "@/components/common/action/PopoverView";
import FrontUnitChooserOption from "@/components/front/FrontUnitChooserOption";
export default {
  name: "FrontUnitChooser",
  components: { FrontUnitChooserOption, PopoverView, DownOutlined },
  setup() {
    const { allOptions, options, current, currentOptions, onUnitChange } =
      useFrontUnit();
    const popVisible = ref(false);
    const showAccessible = computed(() => {
      const a = options.value.accessible;
      return Array.isArray(a) && a.length > 0;
    });
    const onItemClick = (it) => {
      onUnitChange(it.value);
      popVisible.value = false;
    };
    const currentIsMine = computed(() => {
      return options.value.mine.includes(currentOptions.value);
    });
    return {
      popVisible,
      allOptions,
      options,
      current,
      currentOptions,
      showAccessible,
      currentIsMine,
      onItemClick,
    };
  },
};
</script>

<style scoped>
.front-unit-chooser {
  display: flex;
  align-items: center;
  margin-left: 40px;
  width: 278px;
  line-height: 1;
  min-height: 38px;
  padding-right: 8px;
}
.front-unit-chooser-pop {
  width: 278px;
  margin: -12px -16px;
  padding: 0;
}
.front-unit-chooser-group-title,
.front-unit-chooser-group-option {
  display: flex;
  align-items: center;
  height: 40px;
}
.front-unit-chooser-group-title {
  color: var(--rmx-text-3);
  padding-left: 12px;
}
.front-unit-chooser-group-option:hover {
  background: var(--rmx-primary-color-1);
}
.front-unit-chooser-down {
  font-size: 12px;
  color: var(--rmx-text-3);
}
</style>
