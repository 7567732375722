import {
  computed,
  getCurrentInstance,
  ref,
  inject,
  onMounted,
  unref,
  nextTick,
} from "vue";
import { DEFAULT_PAGER_LIMIT, DEFAULT_PAGER_OPTIONS } from "@/conf/constants";
import { ASYNC_DIALOG } from "@/conf/symbols";
import { useCachedProps } from "@/components/common/shared/compInternal";
import { simpleDeepClone } from "@/util/dataUtil";

export const listFilter = (fields = [], filter) => {
  return (list, word) => {
    const key = (word || "").trim().toLowerCase();
    if (!key && !filter) return [...list];
    const keyFunc = (x) =>
      fields.some((field) => x[field] && x[field].toLowerCase().includes(key));
    if (typeof filter === "function") {
      if (key) return list.filter((x) => keyFunc(x) && filter(x));
      return list.filter((x) => filter(x));
    }
    return list.filter(keyFunc);
  };
};

export const useAbstractList = (propRefs, context, handler = {}) => {
  if (!context) context = getCurrentInstance();
  const { emit } = context;
  const {
    asyncLoad,
    loadOnMounted,
    innerSearch,
    innerFilter,
    crossPage,
    fieldKeys,
    showPager,
    showSearch,
    showFilter,
    attachDialog,
  } = propRefs;
  const dialog = attachDialog?.value ? inject(ASYNC_DIALOG, null) : null;
  const { makeRefProp, makeValProp } = useCachedProps(propRefs, context);

  const displaySearchValue = makeRefProp("searchValue", "");
  const displayFilterValue = makeRefProp("filterValue", {});
  const displaySort = makeRefProp("sortValue", {});
  const selectedIds = makeRefProp("selection", []);
  const dataList = makeRefProp("resultList", []);
  const realLoading = ref(false);
  const displayLoading = makeValProp("loading", false);
  const displayPage = makeValProp("page", 1);
  const displaySize = makeValProp("size", DEFAULT_PAGER_LIMIT);
  const displayTotal = makeValProp("total", 0);
  const displaySizeOptions = makeRefProp("sizeOptions", DEFAULT_PAGER_OPTIONS);

  const prevSize = ref(displaySize.value);

  const isAsync = computed(() => typeof asyncLoad.value === "function");

  const hasData = computed(() => dataList.value?.length > 0);
  const filtered = computed(
    () =>
      (innerSearch.value && displaySearchValue.value) ||
      typeof innerFilter.value === "function"
  );

  const doFilter = listFilter(fieldKeys.value, innerFilter.value);
  const displayList = computed(() => {
    if (filtered.value) {
      return doFilter(dataList.value, displaySearchValue.value);
    }
    return dataList.value;
  });

  const isSelected = computed(() => {
    const ids = selectedIds.value;
    return Array.isArray(ids) && ids.length > 0;
  });

  const displayPager = computed(() =>
    dataList.value.length > 0 ? showPager.value : false
  );

  const contextMenuParam = computed(() => {
    const ids = unref(selectedIds.value);
    const list = unref(dataList.value);
    return { ids, list };
  });

  const onPageChange = (newPage, newSize) => {
    if (!crossPage.value) {
      selectedIds.value = [];
    }
    const sizeChanged = prevSize.value !== newSize;
    if (sizeChanged) {
      displaySize.value = newSize;
      prevSize.value = newSize;
    }
    const page = sizeChanged ? 1 : newPage;
    displayPage.value = page;
    emit("pageChange", { page, sizeChanged });
    if (isAsync.value) {
      loadList("page").then(() => {});
    }
  };

  const onSearch = (keyword) => {
    if (!crossPage.value || !showPager.value) {
      selectedIds.value = [];
    }
    if (innerSearch.value) {
      emit("search", {
        keyword,
        toList: () => doFilter(dataList.value, keyword),
      });
    } else {
      emit("search", { keyword });
      if (isAsync.value) {
        displayPage.value = 1;
        loadList("search").then(() => {});
      }
    }
  };

  const onFilter = ({ value, changed }) => {
    if (!crossPage.value || !showPager.value) {
      selectedIds.value = [];
    }
    emit("filter", { value, changed });
    if (isAsync.value) {
      displayPage.value = 1;
      loadList("filter").then(() => {});
    }
  };

  const onSort = ({ column, order }) => {
    if (!crossPage.value || !showPager.value) {
      selectedIds.value = [];
    }
    emit("sort", { column, order });
    if (isAsync.value) {
      displayPage.value = 1;
      loadList("sort").then(() => {});
    }
  };

  const onSetFieldValue = (item, field, val) => (item[field] = val);

  const getSelectionList = () => {
    return dataList.value.filter((x) => selectedIds.value.includes(x.id));
  };
  const getDisplayList = () => displayList.value;
  const onAct = (trigger) => {
    const evtName = `${trigger}Action`;
    return (action) => {
      const ids = selectedIds.value;
      const extra = {};
      if (showSearch.value) extra.searchKeyword = displaySearchValue.value;
      if (showFilter.value) {
        extra.filter = simpleDeepClone(displayFilterValue.value);
      }
      emit(evtName, {
        ...extra,
        action,
        trigger,
        ids,
        filtered: filtered.value,
        pickList: getSelectionList,
        getSelectionList,
        filterList: getDisplayList,
        getDisplayList,
      });
    };
  };

  const onToolbarAction = onAct("tool");
  const onMenuAction = onAct("menu");
  const onIconAction = onAct("icon");

  if (dialog !== null) {
    dialog.setPadding(true);
    dialog.setSubmitCallback(() => {
      const ids = selectedIds.value;
      const list = dataList.value.filter((x) => ids.includes(x.id));
      const ad = attachDialog.value;
      if (ad === true || ad?.autoFinish) {
        dialog.setFinished({ ids, list });
      }
      if (ad === true || ad?.autoClose) {
        dialog.setVisible(false);
      }
    });
  }

  const currentRequest = {};
  const loadList = (trigger) =>
    new Promise((resolve) => {
      let timeout = null;
      currentRequest?.cancel?.();
      currentRequest.id = "req_id_" + Date.now();
      if (typeof asyncLoad.value === "function") {
        timeout = setTimeout(() => {
          displayLoading.value = true;
        }, 150);
        nextTick(() => {
          const data = { trigger };
          if (showPager.value) {
            data.page = displayPage.value;
            data.size = displaySize.value;
            data.limit = displaySize.value;
          }
          if (showSearch.value) {
            data.searchKeyword = displaySearchValue.value;
          }
          if (showFilter.value) {
            data.filter = displayFilterValue.value;
          }
          if (displaySort.value.order) {
            data.sort = displaySort.value;
          }
          const thisId = currentRequest.id;
          const req = asyncLoad.value(data);
          currentRequest.cancel = req.cancelRequest;
          realLoading.value = true;
          req.then((data) => {
            clearTimeout(timeout);
            if (thisId === currentRequest.id) {
              displayLoading.value = false;
              realLoading.value = false;
              displayTotal.value = data.total;
              dataList.value = data.list;
              handler?.afterLoaded?.();
            }
            resolve({});
          });
        }).then(() => {});
      } else {
        resolve({});
      }
    });

  const load = () => {
    displayPage.value = 1;
    displaySearchValue.value = "";
    displayFilterValue.value = {};
    displaySort.value = {};
    selectedIds.value = [];
    return loadList("load");
  };

  const refresh = () => {
    selectedIds.value = [];
    return loadList("refresh");
  };

  const clear = () => {
    displayPage.value = 1;
    displaySearchValue.value = "";
    displayFilterValue.value = {};
    displaySort.value = {};
    selectedIds.value = [];
    dataList.value = [];
  };

  const clearFilter = () => {
    displayFilterValue.value = {};
  };

  onMounted(() => {
    if (loadOnMounted?.value) load().then(() => {});
  });

  return {
    realLoading,
    isSelected,
    hasData,
    dataList,
    displayList,
    displaySearchValue,
    displayFilterValue,
    selectedIds,
    displayLoading,
    displayPager,
    displayPage,
    displaySize,
    displayTotal,
    displaySizeOptions,
    displaySort,
    contextMenuParam,
    onToolbarAction,
    onMenuAction,
    onIconAction,
    onSearch,
    onFilter,
    onPageChange,
    onSort,
    onSetFieldValue,
    load,
    refresh,
    clear,
    clearFilter,
    getSelectionList,
    getDisplayList,
    makeRefProp,
    makeValProp,
  };
};
