<template>
  <attach-view
    ref="attachRef"
    class="file-viewer"
    :show-header="false"
    :file="fileInfo"
    :viewer="previewMethod"
    :no-support-text="'该类型电子文件不支持在线预览'"
  ></attach-view>
</template>

<script>
import { httpLoadPdfOrOfd } from "@/api/common";
import { showConfirm } from "@/util/dialog";
import AttachView from "@/components/common/file/AttachView";

export default {
  name: "FileViewer",
  components: {
    AttachView,
  },
  props: {
    file: { type: Object, required: true },
  },
  emits: ["esc", "forward", "backward"],
  data() {
    return {
      transferType: "",
      ofdType: "",
    };
  },
  computed: {
    fileInfo() {
      return {
        url: this.browseUrl,
      };
    },
    // 预览方式
    previewMethod() {
      let previewMethod = "other";
      if (this.file.transferStatus === 3) {
        if (this.transferType === "pdf") {
          previewMethod = "pdf";
        } else if (this.transferType === "ofd") {
          previewMethod = "ofd";
        }
      } else if (this.file.attachCategory === "I") {
        previewMethod = "image";
      } else if (this.file.attachCategory === "V") {
        previewMethod = "video";
      } else if (this.file.attachCategory === "A") {
        previewMethod = "audio";
      }
      return previewMethod;
    },
    // 预览地址
    browseUrl() {
      return `${this.file.browseUrl}?${Object.keys(this.file.browseParams)
        .map((key) => `${key}=${this.file.browseParams[key]}`)
        .join("&")}`;
    },
  },
  mounted() {
    httpLoadPdfOrOfd().then(({ data, code }) => {
      if (code === 0) {
        this.transferType = data.transferType;
        this.ofdType = data.transpdf;
      }
    });
  },
  methods: {
    onEsc() {
      this.$emit("esc");
    },
    onForward() {
      this.$emit("forward");
    },
    onBackward() {
      this.$emit("backward");
    },
    getSelection() {
      return this.$refs.attachRef.getText();
    },
    print() {
      ["pdf", "ofd"].includes(this.previewMethod)
        ? this.$refs.attachRef.doPrint()
        : showConfirm({
            title: "该附件类型暂不支持打印",
            type: "warn",
            okCancel: false,
            okText: "知道了",
          });
    },
  },
};
</script>

<style scoped>
.file-viewer {
  width: 100%;
  height: 100%;
  padding: 0;
}
</style>
