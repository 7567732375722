import { http } from "@/util/http";
import { httpDownload } from "@/util/fileUtil";
import { HTTP_METHODS } from "@/conf/constants";

/**
 * 获取侧边栏数字
 *
 */
export function httpLoadNavNum() {
  return http.post("utilization/theme/stat", {}).then(({ data, code }) => {
    if (code === 0) {
      return data;
    }
    return Promise.reject();
  });
}

/**
 * 获取编研管理表格数据
 *
 */
export function httpLoadResearchManageTableData(param, status) {
  const { page, limit, filter = {}, searchKeyword = "" } = param;
  return http
    .post("utilization/theme/list", {
      page,
      limit,
      status,
      title: searchKeyword,
      ...filter,
    })
    .then(({ data, code }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 获取参与编研表格数据
 *
 */
export function httpLoadJoinResearchTableData(data) {
  const { page, limit, searchKeyword, filter } = data;
  const param = {
    page,
    limit,
    title: searchKeyword,
    ...filter,
  };
  return http
    .post("utilization/theme/outline/join/list", param)
    .then(({ data, code }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 获取档案审核表格数据
 *
 */
export function httpLoadArchiveApprovalTableData(param) {
  return http
    .post("utilization/theme/apply/list", param)
    .then(({ data, code }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 获取专题分类
 *
 */
export function httpGetClassifyType() {
  return http
    .post("utilization/theme/classify/list", {})
    .then(({ code, data }) => {
      if (code === 0) {
        const list =
          data.list?.map((item) => {
            return {
              ...item,
              value: item.code,
              label: item.name,
            };
          }) || [];
        return { data: list };
      }
      return Promise.reject();
    });
}

/**
 * 新增专题
 *
 */
export function httpAddTask(formData) {
  const { title, type } = formData;
  const param = {
    title,
    type,
  };
  return http
    .post("utilization/theme/save/title", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}

/**
 * 编研中-新增专题
 *
 */
export function httpAddTaskInResearchProcess(formData) {
  const {
    title,
    type,
    classifycode = null,
    classifyname = "",
    planTime = {},
  } = formData;
  const param = {
    title,
    type,
    classifycode,
    classifyname,
    planstarttime: planTime.begin,
    planendtime: planTime.end,
  };
  return http
    .post("utilization/theme/save/subject", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}

/**
 * 计划准备-删除
 *
 */
export function httpDeleteTask(param) {
  return http.post("utilization/theme/delete", param).then(({ code, data }) => {
    if (code === 0) {
      return data || "success";
    }
    return Promise.reject();
  });
}

/**
 * 任务信息详情
 *
 */
export function httpLoadTaskDetail(param) {
  return http.post("utilization/theme/load", param).then(({ code, data }) => {
    if (code === 0) {
      return data;
    }
    return Promise.reject();
  });
}

/**
 * 删除封面图片
 *
 */
export function httpRemoveFile(param) {
  return http.post("common/attach/delete", param).then(({ code, data }) => {
    if (code === 0) {
      return data;
    }
    return Promise.reject();
  });
}

/**
 * 获取附件列表
 *
 */
export function httpLoadAnnexList(param) {
  return http
    .post("utilization/theme/attach/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 获取上传附件限制
 *
 */
export function httpLoadUploadLimit(param) {
  return http
    .post("common/notfile/extensions", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 修改任务标题
 *
 */
export function httpUpdateTaskTitle(param) {
  return http
    .post("utilization/theme/update/title", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 获取封面图片
 *
 */
export function httpLoadCoverImg(param) {
  return http
    .post("utilization/theme/cover/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 编研人员数据
 *
 */
export function httpLoadResearchUserTable(param) {
  return http
    .post("utilization/theme/user/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 添加编研人原
 *
 */
export function httpAddResearchUser(param) {
  return http
    .post("utilization/theme/user/add", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 删除编研人原
 *
 */
export function httpDelResearchUser(param) {
  return http
    .post("utilization/theme/user/delete", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 查询操作日志
 *
 */
export function httpLoadOperationLog(param) {
  return http
    .post("utilization/theme/log/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 查询审批日志
 *
 */
export function httpLoadApprovalLog(param) {
  return http
    .post("utilization/theme/activity/log", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 计划准备详情页-保存
 *
 */
export function httpSaveInfo(formData) {
  const {
    id,
    type,
    classifycode = "",
    classifyname = "",
    planTime = {},
    realTime = {},
    introduct,
    security = "",
    securityname = "",
    unitauthname = "",
    unitauthtype = "",
    authority = "",
    browsescope = "",
    comList = [],
    depList = [],
    userList = [],
  } = formData;

  return http
    .post("utilization/theme/save/info", {
      id,
      type,
      classifycode,
      classifyname,
      planstarttime: planTime?.begin || "",
      planendtime: planTime?.end || "",
      realstarttime: realTime?.begin || "",
      realendtime: realTime?.end || "",
      introduct,
      security,
      securityname,
      unitauthname,
      unitauthtype,
      authority,
      browsescope,
      comList,
      depList,
      userList,
    })
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 不走审批流程提交
 *
 */
export function httpNotApprovalSubmit(param) {
  return http
    .post("personal/out/theme/task/activity/notactivity", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 审批任务详情
 *
 */
export function httpLoadApprovalTaskDetail(param) {
  return http
    .post("personal/out/theme/task/activity/detail", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 作废任务
 *
 */
export function httpSetInvalid(param) {
  return http.post("utilization/theme/remove", param).then(({ code, data }) => {
    if (code === 0) {
      return data || "success";
    }
    return Promise.reject();
  });
}

/**
 * 获取密集
 *
 */
export function httpLoadSecurityData(param) {
  return http
    .post("utilization/theme/security/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 获取任务详情表格数据
 *
 */
export function httpLoadTaskDetailTableData(param) {
  return http
    .post("utilization/theme/outline/all", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 编研大纲列表
 *
 */
export function httpLoadResearchOutline(param) {
  return http
    .post("utilization/theme/outline/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 添加编研节点
 *
 */
export function httpAddResearchNode(param) {
  return http
    .post("utilization/theme/outline/add/leaf", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 修改编研节点
 *
 */
export function httpUpdateResearchNode(param) {
  return http
    .post("utilization/theme/outline/save", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 修订编研节点
 *
 */
export function httpEditResearchNode(param) {
  return http
    .post("utilization/theme/outline/revise", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/*获取参考档案申请审批单详情*/
export function httpGetDetailsOfApplyDataForm(data) {
  return http.post("personal/out/theme/apply/activity/detail", data);
}

/**
 * 批量发布、取消发布
 */
export function httpThemePublish(param) {
  return http
    .post("utilization/theme/publish", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 导出zip包到本地
 */
export function httpThemeExport(param) {
  return httpDownload(
    "utilization/theme/export/zip",
    { param: JSON.stringify(param) },
    null,
    HTTP_METHODS.GET
  );
}

/**
 * 导出zip包到个人文件区
 */
export function httpThemeExportZipFtp(param) {
  return http
    .post("utilization/theme/export/zip/ftp", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 导入zip包
 */
export function httpThemeImportZip(param) {
  return http
    .post("utilization/theme/import/zip", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 从个人文件区导入zip包
 */
export function httpThemeImportZipFtp(param) {
  return http
    .post("utilization/theme/import/zip/ftp", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 结果审批详情
 */
export function httpThemeResultActivityDetail(param) {
  return http
    .post("personal/out/theme/result/activity/detail", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}
/**
 * 根据全宗获取门类信息
 *
 */
export function httpLoadCategoryList(param) {
  return http
    .post("common/setting/types/user/types/list/fondsid", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

/**
 * 获取档案详情
 *
 * @param {object} param - 参数对象.
 * @param {String} param.categoryId - 门类ID.
 * @param {String} param.objectType - 档案对象.
 */
export function httpCategoryMetaData({ categoryId, objectType }) {
  return http
    .post("/common/columns", {
      archtypeid: categoryId,
      objecttype: objectType,
      colstype: "searchbox",
    })
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 修改编研负责人
export function httpEditOriginator(param) {
  return http
    .post("utilization/theme/update/originator", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 加载部门树
export function httpLoadDeptList(param) {
  return http.post("common/dep/tree", param).then(({ code, data }) => {
    if (code === 0) {
      return data;
    }
    return Promise.reject();
  });
}

// 删除编研节点
export function httpDelResearchNode(param) {
  return http
    .post("utilization/theme/outline/delete", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 批量下发
export function httpBatchSend(param) {
  return http
    .post("utilization/theme/outline/send", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 清空编研节点
export function httpClearResearchNode(param) {
  return http
    .post("utilization/theme/outline/delete/all", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 编研完成
export function httpCompleteResearchNode(param) {
  return http
    .post("utilization/theme/outline/complete", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 编研节点退回
export function httpBackResearchNode(param) {
  return http
    .post("utilization/theme/outline/back", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 编研节点详情
export function httpResearchOutlineDetail(param) {
  return http
    .post("utilization/theme/outline/load", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 查看已授权档案
export function httpLoadArchiveList(param) {
  return http
    .post("utilization/theme/user/arch/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 编研节点修订说明
export function httpLoadResearchNodeEditDesc(param) {
  return http
    .post("utilization/theme/outline/log/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}
// 加载编研节点附件
export function httpLoadResearchNodeAnnex(param) {
  return http
    .post("utilization/theme/outline/attach/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}
// 编研节点完成
export function httpResearchNodeFinish(param) {
  return http
    .post("utilization/theme/outline/finish", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}
// 查询申请档案
export function httpLoadApplyArchive(param) {
  return http
    .post("utilization/theme/apply/arch/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 数据申请新id
export function httpInitiative(param) {
  return http
    .post("utilization/theme/apply/insert/initiative", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 数据申请提交
export function httpSubmitDataApply(param) {
  return http
    .post("utilization/theme/apply/submit/info", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 档案编研-管理员完成编研
export function httpResearchNodeAdminFinish(param) {
  return http
    .post("utilization/theme/outline/admin/finish", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}
// 自动归集-元数据字段
export function httpLoadArchiveMetaData(param) {
  return http
    .post("setting/types/fields/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 自动归集-设置条件
export function httpSetCondition(param) {
  return http
    .post("utilization/theme/outline/collect/auto/add", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 自动归集-查询电子档案
export function httpLoadAutoArchiveList(param) {
  return http
    .post("utilization/theme/arch/collect/auto/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 手动归集-添加档案
export function httpAddManualArchive(param) {
  return http
    .post("utilization/theme/arch/add", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 手动归集-删除档案
export function httpDelManualArchive(param) {
  return http
    .post("utilization/theme/arch/delete", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 手动归集-档案排序
export function httpSortManualArchive(param) {
  return http
    .post("utilization/theme/arch/orderindex", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 手动归集-已选档案
export function httpLoadManualArchive(param) {
  return http
    .post("utilization/theme/arch/unchoose/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 数据申请详情
export function httpLoadApplyDetail(param) {
  return http
    .post("utilization/theme/apply/load", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}
// 数据申请-附件列表
export function httpLoadApplyAnnexList(param) {
  return http
    .post("utilization/theme/apply/attach/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}
// 数据申请-操作日志
export function httpLoadDataApplyLog(param) {
  return http
    .post("utilization/theme/apply/log/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}
// 数据申请-审批日志
export function httpLoadDataApplyApprovalLog(param) {
  return http
    .post("utilization/theme/apply/activity/log", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}
// 数据申请-授权档案
export function httpAuthorizationArchive(param) {
  return http
    .post("utilization/theme/user/arch/add", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}
// 数据申请-查看已授权档案
export function httpLoadAuthArchiveList(param) {
  return http
    .post("utilization/theme/apply/arch/auth/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}

// 编研人员-任务分配
export function httpTaskAssign(param) {
  return http
    .post("utilization/theme/user/outline/add", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}

// 重新编研
export function httpReResearchTask(param) {
  return http
    .post("utilization/theme/rewrite", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}

export function httpNoProcess(param) {
  return http
    .post("common/activity/no/process", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}
// 数据申请待办-抢办-更新处理人
export function httpUpdateTodoProcessor(param) {
  return http
    .post("utilization/theme/apply/update/processor", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}
// 编研总篡-查询参考档案申请单数量
export function httpLoadDataApplyNum(param) {
  return http
    .post("utilization/theme/apply/stat", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}

export function httpFileList(param) {
  return http
    .post("arrange/using/system/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}
// 数据申请详情
export function httpLoadDataApplyDetail(id) {
  return http.post("/utilization/theme/apply/load", { id });
}

// 校验所有编研节点正文是否有内容
export function httpValidateContent(param) {
  return http.post("utilization/theme/verify", param).then(({ code, data }) => {
    if (code === 0) {
      return data || "success";
    }
    return Promise.reject();
  });
}

// 检验任务是否已发布
export function httpValidateTaskIsPublish(param) {
  return http
    .post("utilization/theme/special/topic/exists", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 加载专题库
export function httpLoadTopicLib(param) {
  return http
    .post("utilization/special/topic/catalog/list", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

// 发布任务
export function httpPublishTask(param) {
  return http
    .post("utilization/theme/special/topic/to", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}
// 编研节点上下移动
export function httpMoveTreeNode(param) {
  return http
    .post("utilization/theme/outline/orderindex", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}

// 编研节点变更上级
export function httpMoveNodeParent(param) {
  return http
    .post("utilization/theme/outline/change/father", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data || "success";
      }
      return Promise.reject();
    });
}
