import {
  computed,
  inject,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  unref,
  watchEffect,
} from "vue";
import { debounceFunc } from "@/util/uiUtil";
import { BLOCK_RECT_PROVIDER } from "@/components/common/shared/internal";

export function useDraggable(handler, target, draggable) {
  let transform = { x: 0, y: 0 };
  const position = ref("");
  const dragging = ref(false);
  const onMouseDown = (e) => {
    dragging.value = true;
    const sX = e.clientX;
    const sY = e.clientY;
    const { x, y } = transform;
    const rect = target.value.getBoundingClientRect();

    const wW = document.documentElement.clientWidth;
    const wH = document.documentElement.clientHeight;

    const minL = -rect.left + x;
    const minT = -rect.top + y;
    const maxL = wW - rect.left - rect.width + x;
    const maxT = wH - rect.top - rect.height + y;

    const onMouseMove = (ev) => {
      const mX = Math.min(Math.max(x + ev.clientX - sX, minL), maxL);
      const mY = Math.min(Math.max(y + ev.clientY - sY, minT), maxT);
      transform = { x: mX, y: mY };
      position.value = `translate(${mX}px, ${mY}px)`;
    };

    const onMouseUp = () => {
      dragging.value = false;
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const onDraggable = () => {
    if (target.value && handler.value) {
      handler.value.addEventListener("mousedown", onMouseDown);
    }
  };

  const offDraggable = () => {
    if (target.value && handler.value) {
      handler.value.removeEventListener("mousedown", onMouseDown);
    }
  };

  onMounted(() => {
    watchEffect(() => {
      if (draggable.value) {
        onDraggable();
      } else {
        offDraggable();
      }
    });
  });

  onBeforeUnmount(() => {
    offDraggable();
  });

  return { position, dragging };
}

export function useFullscreen(target, callback) {
  const fullscreen = ref(false);
  const isFullscreen = computed({
    get() {
      return fullscreen.value;
    },
    set(val) {
      fullscreen.value = !!val;
      callback?.(fullscreen.value);
    },
  });
  const onFullscreenChange = () => {
    isFullscreen.value = !!document.fullscreenElement;
  };
  const toggle = () => {
    if (isFullscreen.value) {
      document.exitFullscreen().then(() => {});
    } else {
      target.value.requestFullscreen().then(() => {});
    }
  };
  onMounted(() => {
    document.addEventListener("fullscreenchange", onFullscreenChange);
  });
  onBeforeUnmount(() => {
    document.removeEventListener("fullscreenchange", onFullscreenChange);
  });
  return { isFullscreen, toggle };
}

export function useBlockSize() {
  const block = inject(BLOCK_RECT_PROVIDER, null);
  const size = computed(() => {
    const s = block?.size?.value || {};
    return {
      height: s.height || 0,
      width: s.width || 0,
    };
  });
  return { size };
}

export function useSizeObserver(target) {
  const height = ref(0);
  const width = ref(0);
  const size = computed(() => ({
    height: height.value,
    width: width.value,
  }));
  const el = computed(() => unref(target));
  const ro = new ResizeObserver(
    debounceFunc((list) => {
      list.forEach((entry) => {
        height.value = entry.contentRect.height;
        width.value = entry.contentRect.width;
      });
    }, 100)
  );
  onMounted(() => {
    if (el.value) {
      ro.observe(el.value);
    }
  });
  onBeforeUnmount(() => {
    ro.disconnect();
  });
  return { size };
}

export function useSizeProvider(target, provider = "") {
  const { size } = useSizeObserver(target);
  provide(BLOCK_RECT_PROVIDER, { size, provider });
  return { size };
}
