// 档案列表操作
import { createEnum } from "@/mods/common/enum";
import { HIDDEN_RULE } from "@/mods/archive/archiveTreasury/enum/OPERATE_HIDDEN_FLAG";

export const ARCH_LIST_OPERATE = createEnum(
  {
    ADD_ARCHIVE: {
      CODE: "ADD_ARCHIVE",
      NAME: "手工著录",
      ACTION: "addMainTableRecord",
      EDIT: true,
    },
    BATCH_ADD_ARCHIVE: {
      CODE: "BATCH_ADD_ARCHIVE",
      NAME: "批量著录",
      ACTION: "excelImport",
      EDIT: true,
    },
    BATCH_SIGN_ARCHIVE: {
      CODE: "BATCH_SIGN_ARCHIVE",
      NAME: "批量登记",
      ACTION: "batchImport",
      EDIT: true,
    },
    BATCH_EDIT_AUTH: {
      CODE: "BATCH_EDIT_AUTH",
      NAME: "修改权限",
      ACTION: "batchEditAuth",
      EDIT: true,
      HIDDEN_FLAG: [HIDDEN_RULE.NOT_SHI.CODE],
    },
    DEL_DOC: { CODE: "DEL_DOC", NAME: "删除", ACTION: "delDoc" },
    EDIT_FIELD: {
      CODE: "EDIT_FIELD",
      NAME: "修改元数据",
      ACTION: "editField",
      EDIT: true,
    },
    MERGE_COMPONENTS: {
      CODE: "MERGE_COMPONENTS",
      NAME: "合并组件",
      ACTION: "mergeComponents",
      EDIT: true,
    },
    ADJUST_GROUPING: {
      CODE: "ADJUST_GROUPING",
      NAME: "调整分组",
      ACTION: "adjustGrouping",
      EDIT: true,
    },
    SAME_GROUP_NUMBER: {
      CODE: "SAME_GROUP_NUMBER",
      NAME: "批量编号",
      ACTION: "sameGroupNumber",
      EDIT: true,
    },
    UPDATE_FILE_NUMBER: {
      CODE: "UPDATE_FILE_NUMBER",
      NAME: "更新档号",
      ACTION: "updateFileNumber",
      EDIT: true,
    },
    UPDATE_PAGENUMBER: {
      CODE: "UPDATE_PAGENUMBER",
      NAME: "更新卷内页号",
      ACTION: "updatePageNumber",
      EDIT: true,
    },
    CARTONING: { CODE: "CARTONING", NAME: "装盒", ACTION: "cartoning" },
    BATCH_CARTONING: {
      CODE: "BATCH_CARTONING",
      NAME: "批量装盒",
      ACTION: "batchCartoning",
    },
    TURN_ARRANGE_0: {
      CODE: "TURN_ARRANGE_0",
      NAME: "整理完毕",
      ACTION: "turnArrange0",
      MULTIPLE_STATUS: true,
    },
    TURN_ARRANGE_7: {
      CODE: "TURN_ARRANGE_7",
      NAME: "重新整理",
      ACTION: "turnArrange7",
      MULTIPLE_STATUS: true,
    },
    TURN_ARRANGE_28: {
      CODE: "TURN_ARRANGE_28",
      NAME: "需要整理",
      ACTION: "turnArrange28",
      MULTIPLE_STATUS: true,
    },
    TURN_ARRANGE_24: {
      CODE: "TURN_ARRANGE_24",
      NAME: "检查通过",
      ACTION: "turnArrange24",
      MULTIPLE_STATUS: true,
    },
    TURN_ARRANGE_20: {
      CODE: "TURN_ARRANGE_20",
      NAME: "重新检查",
      ACTION: "turnArrange20",
      MULTIPLE_STATUS: true,
    },
    MOVE_TO_COLLECT: {
      CODE: "MOVE_TO_COLLECT",
      NAME: "送接收库",
      ACTION: "moveToArchives0",
      HIDDEN_FLAG: [
        HIDDEN_RULE.NOT_GUAN.CODE,
        HIDDEN_RULE.NO_FILE_CIRCULATION.CODE,
      ],
    },
    MOVE_TO_ARRANGE: {
      CODE: "MOVE_TO_ARRANGE",
      NAME: "送整理库",
      ACTION: "moveToArchives1",
      HIDDEN_FLAG: [HIDDEN_RULE.NO_FILE_CIRCULATION.CODE],
    },
    MOVE_TO_PRESERVE: {
      CODE: "MOVE_TO_PRESERVE",
      NAME: "送保管库",
      ACTION: "moveToArchives2",
      HIDDEN_FLAG: [HIDDEN_RULE.NO_FILE_CIRCULATION.CODE],
    },
    MOVE_TO_UTILIZ: {
      CODE: "MOVE_TO_UTILIZ",
      NAME: "分发至利用库",
      ACTION: "moveToArchives3",
      HIDDEN_FLAG: [HIDDEN_RULE.NO_FILE_CIRCULATION.CODE],
    },
    MOVE_TO_ARCHROOM: {
      CODE: "MOVE_TO_ARCHROOM",
      NAME: "到档案室",
      ACTION: "moveToArchRoom",
      HIDDEN_FLAG: [HIDDEN_RULE.COMPANY.CODE, HIDDEN_RULE.NO_TRANSFER.CODE],
    },
    MOVE_TO_DEPARTMENT: {
      CODE: "MOVE_TO_DEPARTMENT",
      NAME: "到部门兼职",
      ACTION: "moveToDepartment",
      HIDDEN_FLAG: [HIDDEN_RULE.DEPARTMENT.CODE, HIDDEN_RULE.NO_TRANSFER.CODE],
    },
    MOVE_TO_PERSON: {
      CODE: "MOVE_TO_PERSON",
      NAME: "到整理人",
      ACTION: "moveToPerson",
      HIDDEN_FLAG: [HIDDEN_RULE.DEPARTMENT.CODE, HIDDEN_RULE.NO_TRANSFER.CODE],
    },
    BATCH_MOUNT_FILES: {
      CODE: "BATCH_MOUNT_FILES",
      NAME: "批量挂接",
      ACTION: "batchMountFiles",
      EDIT: true,
    },
    BATCH_DOWNLOAD: {
      CODE: "BATCH_DOWNLOAD",
      NAME: "批量下载",
      ACTION: "batchDownload",
    },
    BATCH_ELCTRO_PRINT: {
      CODE: "BATCH_ELCTRO_PRINT",
      NAME: "批量打印",
      ACTION: "batchElctroPrint",
    },
    UNEXCEL_EXPORT_SELECTED: {
      CODE: "UNEXCEL_EXPORT_SELECTED",
      NAME: "导出所选",
      ACTION: "unexcelExport0",
    },
    UNEXCEL_EXPORT_ALL: {
      CODE: "UNEXCEL_EXPORT_ALL",
      NAME: "导出全部",
      ACTION: "unexcelExport1",
    },
    PRINT_RECORD_SELECTED: {
      CODE: "PRINT_RECORD_SELECTED",
      NAME: "打印所选",
      ACTION: "printRecord0",
    },
    PRINT_RECORD_ALL: {
      CODE: "PRINT_RECORD_ALL",
      NAME: "打印全部",
      ACTION: "printRecord1",
    },
    FOUR_NATURES_CHECK: {
      CODE: "FOUR_NATURES_CHECK",
      NAME: "检测",
      ACTION: "fourNaturesCheck",
    },
    COPY_TO_ARRANGE: {
      CODE: "COPY_TO_ARRANGE",
      NAME: "复制到本门类",
      ACTION: "copyToArrange",
      HIDDEN_FLAG: [HIDDEN_RULE.IS_PROJECT.CODE],
    },
    COPY_TO_OTHER_ARCHIVES: {
      CODE: "COPY_TO_OTHER_ARCHIVES",
      NAME: "复制到其他门类",
      ACTION: "copyToOtherArchives",
      HIDDEN_FLAG: [HIDDEN_RULE.IS_PROJECT.CODE],
    },
    MOVE_TO_OTHER_ARCHIVES: {
      CODE: "MOVE_TO_OTHER_ARCHIVES",
      NAME: "移动到其他门类",
      ACTION: "moveToOtherArchives",
      HIDDEN_FLAG: [HIDDEN_RULE.IS_PROJECT.CODE],
    },
    TO_UNSAVED: {
      CODE: "TO_UNSAVED",
      NAME: "移动到不归档库",
      ACTION: "tounsaved",
      HIDDEN_FLAG: [
        HIDDEN_RULE.PERSON.CODE,
        HIDDEN_RULE.NO_VIEW_UNSAVEDATA.CODE,
      ],
    },
    TO_UNCOLLECTED: {
      CODE: "TO_UNCOLLECTED",
      NAME: "转待整理",
      ACTION: "touncollected",
    },
    // 汇总（盒）
    SUMMARIZE_BOX: {
      CODE: "SUMMARIZE_BOX",
      NAME: "汇总",
      ACTION: "summarizeBox",
      EDIT: true,
    },
    // 汇总（卷）
    VOLUME_SUMMARY: {
      CODE: "VOLUME_SUMMARY",
      NAME: "汇总",
      ACTION: "volumeSummary",
      EDIT: true,
    },
    REDUCE_DOC: { CODE: "REDUCE_DOC", NAME: "还原", ACTION: "reduceDoc" },
    CHANGE_BOX: {
      CODE: "CHANGE_BOX",
      NAME: "换盒",
      ACTION: "changeBox",
      EDIT: true,
    },
    // 拆离（盒内）
    NOT_CARTONING: {
      CODE: "NOT_CARTONING",
      NAME: "拆离",
      ACTION: "notCartoning",
      EDIT: true,
    },
    // 拆离（卷内）
    NOT_GROUP_PAPERS: {
      CODE: "NOT_GROUP_PAPERS",
      NAME: "拆离",
      ACTION: "notGroupPapers",
      EDIT: true,
    },
    SCATTER: { CODE: "SCATTER", NAME: "打散", ACTION: "scatter", EDIT: true },
    GROUP_PAPERS_0: {
      CODE: "GROUP_PAPERS_0",
      NAME: "换卷",
      ACTION: "groupPapers",
      EDIT: true,
    },
    GROUP_PAPERS_1: {
      CODE: "GROUP_PAPERS_1",
      NAME: "加入指定案卷",
      ACTION: "groupPapers",
      EDIT: true,
    },
    FILE_REORDER: {
      CODE: "FILE_REORDER",
      NAME: "卷内调序",
      ACTION: "fileReorder",
      EDIT: true,
    },
    GROUP_VOL: {
      CODE: "GROUP_VOL",
      NAME: "组卷",
      ACTION: "groupVol",
      EDIT: true,
    },
    BATCH_GROUP_VOL: {
      CODE: "BATCH_GROUP_VOL",
      NAME: "批量组卷",
      ACTION: "batchGroupVol",
      EDIT: true,
    },
    UP_SHELF: { CODE: "UP_SHELF", NAME: "上架", ACTION: "upShelf" },
    DOWN_SHELF: { CODE: "DOWN_SHELF", NAME: "下架", ACTION: "downShelf" },
    JOIN_APPRASIAL_3: {
      CODE: "JOIN_APPRASIAL_3",
      NAME: "开放鉴定",
      ACTION: "joinApprasial3",
      HIDDEN_FLAG: [HIDDEN_RULE.NO_APPRAISAL_QXJB.CODE],
    },
    JOIN_APPRASIAL_2: {
      CODE: "JOIN_APPRASIAL_2",
      NAME: "保密鉴定",
      ACTION: "joinApprasial2",
      HIDDEN_FLAG: [HIDDEN_RULE.NO_APPRAISAL_SECURITY.CODE],
    },
    JOIN_APPRASIAL_1: {
      CODE: "JOIN_APPRASIAL_1",
      NAME: "价值鉴定",
      ACTION: "joinApprasial1",
      HIDDEN_FLAG: [HIDDEN_RULE.NO_APPRAISAL_RETENTIONPERIOD.CODE],
    },
    TURN_ARCH_MANAGER: {
      CODE: "TURN_ARCH_MANAGER",
      NAME: "转档案室整理",
      ACTION: "turnArchManager",
      HIDDEN_FLAG: [HIDDEN_RULE.DEPARTMENT.CODE],
    },
    TURN_BUMEN_MANAGER: {
      CODE: "TURN_BUMEN_MANAGER",
      NAME: "转部门整理",
      ACTION: "turnBumenManager",
      HIDDEN_FLAG: [HIDDEN_RULE.COMPANY.CODE],
    },
    TURN_OTHER_PEOPLE: {
      CODE: "TURN_OTHER_PEOPLE",
      NAME: "调整整理人",
      ACTION: "turnOtherPeople",
    },
    CHANGE_PROJECT: {
      CODE: "CHANGE_PROJECT",
      NAME: "变更所属项目",
      ACTION: "changeProject",
      HIDDEN_FLAG: [HIDDEN_RULE.NOT_PROJECT.CODE],
    },
    SYNC_USING_STORAGE: {
      CODE: "SYNC_USING_STORAGE",
      NAME: "同步利用库",
      ACTION: "syncUsingStorage",
      HIDDEN_FLAG: [HIDDEN_RULE.IS_SYNC_TO_USE.CODE],
    },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);
