<template>
  <div class="front-header">
    <div class="front-header-logo" @click="gotoIndex">
      <img
        v-if="hasLogoImg"
        class="front-header-logo-img"
        :src="logoSrc"
        :alt="logoText"
      />
      <span v-if="hasLogoText">{{ logoText }}</span>
    </div>
    <template v-if="!purePage">
      <front-unit-chooser />
      <front-nav-menus class="front-header-menus" />
      <div class="front-header-right">
        <div class="front-header-icons">
          <div class="front-header-icons-item" @click="showBorrowCart">
            <common-icon class="front-header-icon" icon="shopping" />
            <count-tag dot :count="userCounts.borrowCart" />
          </div>
          <front-pop-menu :menus="notificationMenus" @click="showNotification">
            <div class="front-header-icons-item">
              <common-icon class="front-header-icon" icon="remind" />
              <count-tag dot :count="userCounts.notification" />
            </div>
          </front-pop-menu>
          <front-pop-menu :menus="utilizationMenus" @click="jumpToUrl">
            <div class="front-header-icons-item">
              <common-icon class="front-header-icon" icon="folder-focus-one" />
              <count-tag dot :count="userCounts.utilization" />
            </div>
          </front-pop-menu>
        </div>
        <a-popover placement="bottomRight" v-model:visible="menuVisible">
          <div class="front-header-user">
            <user-avatar :user="user"></user-avatar>
            <down-outlined class="front-header-user-icon" />
          </div>
          <template #content>
            <user-popup-view front v-model:visible="menuVisible" />
          </template>
        </a-popover>
        <div class="front-header-icons">
          <front-pop-menu
            :value="language"
            :menus="languages"
            @click="onLanguageChange($event.action)"
          >
            <div class="front-header-icons-item">
              <common-icon class="front-header-icon" icon="translate" />
            </div>
          </front-pop-menu>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import { useRouter } from "vue-router";
import { useFrontHeader } from "@/components/front/frontNav";
import {
  useSystemLanguage,
  useSystemLogo,
} from "@/components/common/shared/globalInternal";
import { DRAWER_VIEW } from "@/conf/symbols";
import { DownOutlined } from "@ant-design/icons-vue";
import UserAvatar from "@/components/user/UserAvatar";
import UserPopupView from "@/components/user/UserPopupView";
import FrontNavMenus from "@/components/front/FrontNavMenus";
import CountTag from "@/components/common/display/CountTag";
import FrontUnitChooser from "@/components/front/FrontUnitChooser";
import CommonIcon from "@/components/icon/CommonIcon";
import FrontPopMenu from "@/components/front/FrontPopMenu";

export default {
  name: "FrontHeader",
  components: {
    FrontPopMenu,
    CommonIcon,
    FrontUnitChooser,
    CountTag,
    FrontNavMenus,
    UserPopupView,
    UserAvatar,
    DownOutlined,
  },
  setup() {
    const router = useRouter();
    const drawer = inject(DRAWER_VIEW, null);
    const menuVisible = ref(false);
    const { logoSrc, logoText, hasLogoImg, hasLogoText } =
      useSystemLogo("front");
    const { user, userCounts, utilizationMenus, notificationMenus, purePage } =
      useFrontHeader();
    const { language, languages, onLanguageChange } = useSystemLanguage();
    const showNotification = (e) => {
      drawer?.openDrawer("notificationList", { entry: e.action });
    };
    const showBorrowCart = () => {
      drawer?.openDrawer("borrowCart", {});
    };
    const jumpToUrl = (e) => {
      router.push(e.action);
    };
    const gotoIndex = () => {
      router.push("/");
    };
    return {
      purePage,
      menuVisible,
      user,
      logoSrc,
      logoText,
      hasLogoImg,
      hasLogoText,
      notificationMenus,
      userCounts,
      utilizationMenus,
      language,
      languages,
      onLanguageChange,
      showNotification,
      showBorrowCart,
      jumpToUrl,
      gotoIndex,
    };
  },
};
</script>

<style scoped>
.front-header {
  display: flex;
  align-items: center;
  padding: 0 40px;
  min-width: 1000px;
  height: 100%;
}
.front-header-logo {
  font-size: 22px;
  color: var(--rmx-text-1);
  line-height: 1.3;
  white-space: nowrap;
  cursor: pointer;
}
.front-header-logo-img {
  height: 40px;
  margin-right: 8px;
}
.front-header-logo-img:last-child {
  margin-right: 0;
}
.front-header-menus {
  margin-left: 60px;
  margin-right: 60px;
  flex: 1;
  max-width: calc(100vw - 700px);
}
.front-header-right {
  display: flex;
  align-items: center;
  line-height: 1;
}
.front-header-icons {
  display: flex;
  align-items: center;
  margin-right: 32px;
  line-height: 1;
  gap: 24px;
}
.front-header-icons-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 64px;
}
.front-header-icons:last-child {
  margin-right: 0;
}
.front-header-icon {
  font-size: 22px;
  color: var(--rmx-icon-2);
}
.front-header-icon:hover {
  color: var(--rmx-primary-color);
}
.front-header-user {
  display: flex;
  align-items: center;
  margin-right: 32px;
}
.front-header-user-icon {
  font-size: 16px;
  color: var(--rmx-icon-3);
  margin-left: 4px;
}
</style>
