<script>
import { computed, onMounted, ref, toRefs } from "vue";
import { snakeCase } from "@/util/strUtil";
import dayjs from "dayjs";
import { i18n } from "@/conf/lang";
import {
  FORM_ITEM_BOOLEAN,
  FORM_ITEM_NUMBER,
  FORM_ITEM_WIDTH,
  UI_DIRECTION,
  UI_SIZE,
} from "@/conf/constants";
import { PROPS_INPUT_ITEM_ALL } from "@/components/common/shared/compAttrs";
import { generateTree } from "@/util/dataUtil";
import {
  Alert,
  AutoComplete,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  FormItemRest,
  Input,
  InputNumber,
  InputPassword,
  RadioGroup,
  RangePicker,
  Rate,
  Select,
  Slider,
  Switch,
  Textarea,
  TimePicker,
  TimeRangePicker,
  TreeSelect,
} from "ant-design-vue";
import { DownOutlined } from "@ant-design/icons-vue";
import ColorPicker from "@/components/common/form/ColorPicker";
import TagsInput from "@/components/common/form/TagsInput";

const BLOCK_TYPE = [
  "radio_group",
  "radio_buttons",
  "checkbox_group",
  "select",
  "tree_select",
  "date",
  "time",
  "number",
  "slider",
  "tags",
  "tags_area",
];

const OPTION_TYPE = [
  "radio_group",
  "radio_buttons",
  "checkbox_group",
  "select",
];

const INPUT_OPTIONS_TYPE = ["radio_group", "checkbox_group", "select"];

const AREA_TYPE = ["textarea", "tags_area"];

const TIME_FORMAT = { second: "HH:mm:ss", minute: "HH:mm", hour: "HH" };

const defValue = (type, compat, range) => {
  if (range) return { begin: "", end: "" };
  if (FORM_ITEM_BOOLEAN.includes(type)) {
    if (compat) return 0;
    return false;
  }
  if (FORM_ITEM_NUMBER.includes(type)) return 0;
  return "";
};

const emptyPlaceHolder = (text, style) => (
  <Alert message={text} class={["rmx-form-tips"]} style={style} />
);

export default {
  name: "FormInput",
  emits: ["update:value", "change"],
  props: { ...PROPS_INPUT_ITEM_ALL },
  setup(props, { emit, expose }) {
    const propRefs = toRefs(props);
    const compRef = ref(null);
    const { options, range, height } = propRefs;
    const snakeType = computed(() => snakeCase(propRefs.type.value));
    const itemValue = computed(() =>
      propRefs.readonly.value && propRefs.value.value === undefined
        ? defValue(snakeType.value, propRefs.compat.value, range.value)
        : propRefs.value.value
    );
    const processedOptions = computed(() =>
      Array.isArray(options.value) && OPTION_TYPE.includes(snakeType.value)
        ? options.value.map((x) => ({
            disabled: !!x.disabled,
            label: x.text || x.label,
            value: x.value,
          }))
        : []
    );
    const style = computed(() => {
      const style = {};
      const w = propRefs.width.value;
      if (w) {
        if (w === "block" && BLOCK_TYPE.includes(snakeType.value)) {
          style.width = "100%";
        } else {
          style.width = FORM_ITEM_WIDTH[w] || w;
        }
      }
      if (height.value && AREA_TYPE.includes(snakeType.value)) {
        style.height = height.value;
      }
      return style;
    });
    const inputVal = ref("");
    const focus = () => compRef.value?.focus();
    const blur = () => compRef.value?.blur();
    const _set = (val) => {
      if (!propRefs.readonly.value) {
        emit("update:value", val);
        emit("change", val);
      }
    };
    onMounted(() => {
      if (
        INPUT_OPTIONS_TYPE.includes(snakeType.value) &&
        propRefs.input.value &&
        Array.isArray(itemValue.value)
      ) {
        inputVal.value = itemValue.value.find(
          (x) => !processedOptions.value.map((x) => x.value).includes(x)
        );
      }
    });
    expose({ focus, blur });
    return () => {
      const _type = snakeType.value;
      const _text = propRefs.text.value;
      if (_type === "label") {
        return (
          <span className={"ant-form-text"}>
            {_text || propRefs.value.value}
          </span>
        );
      }
      const _name = propRefs.name.value;
      const _disabled = propRefs.disabled.value;
      const _size = UI_SIZE[propRefs.size.value];
      const _hint = propRefs.placeholder.value;
      const _clearable = propRefs.clearable.value;
      const _compat = propRefs.compat.value;
      const _maxlength = propRefs.maxlength.value;
      const _max = propRefs.max.value;
      const _min = propRefs.min.value;
      const _step = propRefs.step.value;
      const _multiple = !!propRefs.multiple.value;
      const _maxCount = propRefs.maxCount.value;
      const _mode = propRefs.mode.value;
      const _input = propRefs.input.value;
      const _bordered = propRefs.bordered.value;
      const isVertical = propRefs.direction.value === UI_DIRECTION.vertical;
      const _val = itemValue.value;
      const _options = processedOptions.value;
      const createNumber = (numRef) => (
        <InputNumber
          ref={numRef}
          placeholder={_hint}
          value={_val}
          size={_size}
          disabled={_disabled}
          max={_max}
          min={_min}
          step={_step}
          bordered={_bordered}
          style={style.value}
          onChange={(e) => _set(e)}
        />
      );
      if (_type === "radio_group" || _type === "radio_buttons") {
        const optionType = _type === "radio_buttons" ? "button" : undefined;
        const name = _type === "radio_buttons" ? "" : "rmx-option-group";
        const className = isVertical
          ? ["rmx-radio-group-vertical", name]
          : name;
        if (_options.length < 1 && !_input) {
          return emptyPlaceHolder(_hint, style);
        }
        return (
          <RadioGroup
            name={_name}
            class={className}
            options={_options}
            optionType={optionType}
            value={_val}
            disabled={_disabled}
            style={style.value}
            onChange={(e) => _set(e.target.value)}
          />
        );
      } else if (_type === "checkbox_group") {
        if (_options.length < 1 && !_input) {
          return emptyPlaceHolder(_hint, style);
        }
        const name = "rmx-option-group";
        const className = isVertical
          ? [name, "rmx-checkbox-group-vertical"]
          : name;
        const selfInputChange = (shouldAdd) => {
          const opts = _options.map((x) => x.value);
          const newValues = (_val || []).filter((x) => opts.includes(x));
          if (shouldAdd && inputVal.value) {
            newValues.push(inputVal.value);
          }
          _set(newValues);
        };
        const content = _input ? (
          <>
            {_options.map((it) => {
              return (
                <Checkbox
                  class={"ant-checkbox-group-item"}
                  name={_name}
                  disabled={it.disabled}
                  value={it.value}
                >
                  {it.label}
                </Checkbox>
              );
            })}
            <FormItemRest>
              <Checkbox
                class={"ant-checkbox-group-item"}
                name={_name}
                value={inputVal.value}
                onChange={(e) => selfInputChange(e.target.checked)}
              >
                <div className={"rmx-form-input-able-option"}>
                  <div className="rmx-form-input-able-option-text">
                    {i18n.global.t("things.other")}
                  </div>
                  <Input
                    v-model={[inputVal.value, "value"]}
                    placeholder={_hint}
                    size={_size}
                    onChange={() => selfInputChange(true)}
                  />
                </div>
              </Checkbox>
            </FormItemRest>
          </>
        ) : undefined;
        const checkGroup = (
          <CheckboxGroup
            name={_name}
            class={className}
            options={_input ? undefined : _options}
            value={_val}
            disabled={_disabled}
            style={style.value}
            onChange={(e) => _set(e)}
          >
            {content}
          </CheckboxGroup>
        );
        if (propRefs.selectAll.value) {
          const validOptions = _options.filter((o) => !o.disabled);
          const checked = _val?.length >= validOptions.length;
          return (
            <div style={style.value}>
              <FormItemRest>
                <div className={"rmx-form-check-select-all"}>
                  <Checkbox
                    name={_name + "_all"}
                    checked={checked}
                    disabled={_disabled}
                    onChange={(e) => {
                      if (e.target.checked) {
                        _set(validOptions.map((x) => x.value));
                      } else {
                        _set([]);
                      }
                    }}
                  >
                    {i18n.global.t("actions.select_all")}
                  </Checkbox>
                </div>
              </FormItemRest>
              {checkGroup}
            </div>
          );
        }
        return checkGroup;
      } else if (_type === "checkbox") {
        const checked = _compat ? +_val === 1 : _val;
        const update = _compat ? (e) => _set(e ? 1 : 0) : _set;
        return (
          <Checkbox
            ref={compRef}
            name={_name}
            checked={checked}
            disabled={_disabled}
            onChange={(e) => update(e.target.checked)}
          >
            {_text}
          </Checkbox>
        );
      } else if (_type === "switch") {
        const checked = _compat ? +_val || 0 : _val;
        const compatAttr = _compat
          ? {
              checkedValue: 1,
              unCheckedValue: 0,
            }
          : {};
        const textAttr = Array.isArray(_text)
          ? {
              checkedChildren: _text[1] || _text[0],
              unCheckedChildren: _text[0],
            }
          : {};
        return (
          <Switch
            ref={compRef}
            name={_name}
            checked={checked}
            disabled={_disabled}
            {...compatAttr}
            {...textAttr}
            onChange={(e) => _set(e)}
          />
        );
      } else if (_type === "tags" || _type === "tags_area") {
        const isArea = _type === "tags_area";
        const resize = isArea && propRefs.resize.value;
        return (
          <TagsInput
            bordered={_bordered}
            multiline={isArea}
            placeholder={_hint}
            input={_input}
            disabled={_disabled}
            readonly={propRefs.readonly.value}
            max={_max}
            icon={propRefs.optionsIcon.value}
            resize={resize}
            style={style.value}
            value={_val}
            onChange={(e) => _set(e)}
          />
        );
      } else if (_type === "date") {
        if (range.value) {
          const val = ref();
          if (_val) {
            const { begin, end } = _val;
            const v = [];
            if (begin) v.push(dayjs(begin));
            if (end) v.push(dayjs(end));
            val.value = v.length === 2 ? v : [];
          }
          const dateRangeChange = (dates) => {
            if (Array.isArray(dates)) {
              const val = { begin: dates[0], end: dates[1] };
              if (val.begin && val.end) _set(val);
              else _set(undefined);
            } else {
              _set(undefined);
            }
          };
          const rangeHint = Array.isArray(_hint) ? _hint : [_hint];
          return (
            <RangePicker
              ref={compRef}
              name={_name}
              value={val.value}
              size={_size}
              placeholder={rangeHint}
              disabled={_disabled}
              picker={_mode}
              style={style.value}
              allowClear={_clearable}
              onChange={(e, dates) => dateRangeChange(dates)}
            />
          );
        }
        const val = ref();
        val.value = null;
        if (_val) val.value = dayjs(_val);
        return (
          <DatePicker
            ref={compRef}
            name={_name}
            value={val.value}
            size={_size}
            placeholder={_hint}
            disabled={_disabled}
            picker={_mode}
            style={style.value}
            allowClear={_clearable}
            onChange={(e, str) => _set(str)}
          />
        );
      } else if (_type === "time") {
        const format = TIME_FORMAT[_mode] ?? TIME_FORMAT.second;
        if (range.value) {
          const val = ref();
          if (_val) {
            const { begin, end } = _val;
            const v = [];
            if (begin) v.push(begin);
            if (end) v.push(end);
            val.value = v.length === 2 ? v : [];
          }
          const timeRangeChange = (arr) => {
            if (Array.isArray(arr)) {
              const val = { begin: arr[0], end: arr[1] };
              if (val.begin && val.end) _set(val);
              else _set(undefined);
            } else {
              _set(undefined);
            }
          };
          const rangeHint = Array.isArray(_hint) ? _hint : [_hint];
          return (
            <TimeRangePicker
              ref={compRef}
              name={_name}
              value={val.value}
              size={_size}
              placeholder={rangeHint}
              disabled={_disabled}
              style={style.value}
              allowClear={_clearable}
              format={format}
              valueFormat={format}
              onChange={timeRangeChange}
            />
          );
        }
        return (
          <TimePicker
            ref={compRef}
            name={_name}
            value={_val}
            size={_size}
            placeholder={_hint}
            disabled={_disabled}
            style={style.value}
            format={format}
            valueFormat={format}
            allowClear={_clearable}
            onChange={(e) => _set(e)}
          />
        );
      } else if (_type === "select") {
        const _search = propRefs.search.value;
        const useAC = _input && !_multiple;
        let filterOption = _search;
        if (filterOption === true) {
          filterOption = (v, opt) => (opt.label || "").includes(v);
        } else if (filterOption === "CARD") {
          filterOption = (v, opt) => v === opt.label || v === opt.value;
        }
        const showSearch = !!_search;
        const isVirtual = _options.length > 50;
        if (useAC) {
          return (
            <AutoComplete
              ref={compRef}
              options={_options}
              value={_val}
              disabled={_disabled}
              bordered={_bordered}
              style={style.value}
              allowClear={_clearable}
              virtual={isVirtual}
              onChange={(e) => _set(e)}
            >
              <Input
                size={_size}
                placeholder={_hint}
                v-slots={{
                  suffix: () => (
                    <DownOutlined
                      style={{ color: "#d9d9d9", fontSize: "12px" }}
                    />
                  ),
                }}
              />
            </AutoComplete>
          );
        }
        const mode = _multiple ? (_input ? "tags" : "multiple") : undefined;
        const tagCount = _maxCount < 0 ? "responsive" : _maxCount;
        return (
          <Select
            ref={compRef}
            placeholder={_hint}
            options={_options}
            value={_val}
            size={_size}
            disabled={_disabled}
            mode={mode}
            filterOption={filterOption}
            showSearch={showSearch}
            bordered={_bordered}
            style={style.value}
            allowClear={_clearable}
            maxTagCount={tagCount}
            virtual={isVirtual}
            onChange={(e) => _set(e)}
          />
        );
      } else if (_type === "tree_select") {
        const checkStrictly = propRefs.checkStrictly.value;
        const tagCount = _maxCount < 0 ? "responsive" : _maxCount;
        const treeList = options.value.map((x) => ({
          title: x.title || x.text || x.label,
          value: x.value,
          id: x.id,
          pId: x.pId,
          disabled: !!x.disabled,
        }));
        const tree = Array.isArray(options.value)
          ? generateTree(treeList, { key: "id", addMark: true })
          : [];
        const treeValue = checkStrictly
          ? (_val || []).map((v) => {
              const it = treeList.find((x) => x.value === v);
              if (it) return it;
            })
          : _val;
        const setTreeValue = checkStrictly
          ? (e) => {
              _set((e || []).map((x) => x.value));
            }
          : (e) => _set(e);
        return (
          <TreeSelect
            ref={compRef}
            placeholder={_hint}
            treeData={tree}
            value={treeValue}
            size={_size}
            disabled={_disabled}
            bordered={_bordered}
            style={style.value}
            allowClear={_clearable}
            treeLine={{ showLeafIcon: false }}
            treeCheckable={!!_multiple}
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            treeCheckStrictly={checkStrictly}
            maxTagCount={tagCount}
            onChange={setTreeValue}
          />
        );
      } else if (_type === "color") {
        return (
          <ColorPicker
            options={options.value}
            value={_val}
            disabled={_disabled}
            size={_size}
            input={_input}
            onChange={(e) => _set(e)}
          />
        );
      } else if (_type === "rate") {
        return (
          <Rate
            value={_val}
            count={_max}
            disabled={_disabled}
            tooltips={_text}
            allowClear={_clearable}
            onChange={(e) => _set(e)}
          />
        );
      } else if (_type === "slider") {
        const slider = (
          <div className={"rmx-form-slider"} style={style.value}>
            <Slider
              value={_val || 0}
              max={_max}
              min={_min}
              step={_step}
              disabled={_disabled}
              onChange={(e) => _set(e)}
            />
          </div>
        );
        return _input ? (
          <div className={"rmx-form-slider-with-input"}>
            {slider}
            <FormItemRest>{createNumber()}</FormItemRest>
          </div>
        ) : (
          slider
        );
      } else if (_type === "number") {
        return createNumber(compRef);
      } else if (_type === "textarea") {
        const className = !propRefs.resize.value
          ? "rmx-form-item-not-resize"
          : "";
        return (
          <Textarea
            ref={compRef}
            class={className}
            placeholder={_hint}
            value={_val}
            size={_size}
            disabled={_disabled}
            maxlength={_maxlength}
            showCount={!!propRefs.showCount.value}
            bordered={_bordered}
            style={style.value}
            allowClear={_clearable}
            onChange={(e) => _set(e.target.value)}
          />
        );
      } else if (_type === "password") {
        return (
          <InputPassword
            ref={compRef}
            placeholder={_hint}
            value={_val}
            size={_size}
            disabled={_disabled}
            maxlength={_maxlength}
            visibilityToggle={!!propRefs.showToggle.value}
            bordered={_bordered}
            style={style.value}
            allowClear={_clearable}
            onChange={(e) => _set(e.target.value)}
          />
        );
      } else {
        return (
          <Input
            ref={compRef}
            placeholder={_hint}
            value={_val}
            size={_size}
            disabled={_disabled}
            maxlength={_maxlength}
            showCount={!!propRefs.showCount.value}
            bordered={_bordered}
            style={style.value}
            allowClear={_clearable}
            onChange={(e) => _set(e.target.value)}
          />
        );
      }
    };
  },
};
</script>
