import { http } from "@/util/http";
import {
  ARCH_STORAGE,
  ARCH_OBJTYPE,
  ARCH_STATUS,
} from "@/mods/archive/archiveTreasury/enum";
import { ARCH_LIST_TAB } from "@/mods/archive/archiveTreasury/enum";
import { httpGetFileSizeLimit } from "@/api/common";
import store from "@/store";
import { getQueryStr } from "@/util/strUtil";

// 档案列表是否已配置目录树
export function httpCheckCatalogTree({ repotype }) {
  return http
    .post("arrange/using/repodoc/catalog/have/tree", {
      repotype:
        ARCH_STORAGE[ARCH_STORAGE.getKeyFromValue(repotype)]?.REALCODE ??
        repotype,
      paramscenetype: repotype === ARCH_STORAGE.UTILIZ.CODE,
    })
    .then(({ data }) => data);
}
// 档案列表加载目录树
export function httpLoadCatalogTree({
  id, // 目录树所选节点的id，初始化时为空
  repotype, // 库类型，
  archtypeid, // 门类id
  objecttype, // 档案对象 【1项目 2案卷 3文件（全部文件） 4盒 5卷内文件 6单文件 7盒内文件 -1未装盒文件 -2散文件 -3项目内散文件 -4卷内文件删除】
  managestatus, // // 数据层级【2公司级 1部门级 0个人级 档案馆默认为公司级】
  cattype, // 当前目录树节点类型
  fondsid, // 全宗id
  groupnodeid, // 当前目录树节点所属分组节点id
  groupconditions = [], // 当前目录树节点的分组条件
  projectId = "",
}) {
  return http
    .post("arrange/using/repodoc/catalog/tree", {
      nodeid: id,
      repotype:
        ARCH_STORAGE[ARCH_STORAGE.getKeyFromValue(repotype)]?.REALCODE ??
        repotype,
      archtypeid,
      objecttype,
      managestatus,
      cattype,
      fondsid,
      groupnodeid,
      groupconditions,
      // 档案状态【档案状态 0待整理 1已归档 7已整理 20待检查 24已检查 28需整理 9回收站 11不归档（已丢弃的状态：2移交中 3鉴定中 4待销毁 5已销毁）】
      status:
        ARCH_STORAGE[ARCH_STORAGE.getKeyFromValue(repotype)]["DEF_STATUS"],
      // 是否利用库，true是 false否
      paramscenetype: repotype === ARCH_STORAGE.UTILIZ.CODE,
      // 项目id
      projectid: projectId,
    })
    .then(({ data }) => data);
}
// 档案列表获取表格查询列的下拉项
export function httpGetArchListQueryCols({ archtypeid, objecttype }) {
  // 按照档案对象（ARCH_OBJTYPE）的枚举，匹配不同的接口地址
  let urls = {
    UNBOX: "/common/columns", // 未装盒文件
    UNVOL: "/common/columns", // 散文件
    DOC: "/common/columns", // 文件
    BOX: "/arrange/repo/repodoc/boxcols", // 盒
    VOL: "/common/arrange/repodoc/volcols", // 案卷
  };
  return http
    .post(urls[ARCH_OBJTYPE.getKeyFromValue(objecttype)], {
      archtypeid,
      objecttype,
      colstype: "searchbox",
    })
    .then(({ data }) => data);
}
// 档案列表获取表格列的配置
export function httpGetArchListColsSetting({
  storage,
  archtypeid,
  objecttype,
}) {
  return http
    .post("arrange/using/uitableview/list", {
      archtypeid,
      objecttype,
      repotype: storage,
      paramscenetype: storage === ARCH_STORAGE.UTILIZ.CODE, // 是否利用库，true是 false否
    })
    .then(({ data }) => {
      if (data?.uiTableViewDOs?.length > 0) {
        // 剔除普通元数据的电子文件数量列，保留特殊列中的电子文件列，否则档案列表中会出现两个附件列
        const index = data.uiTableViewDOs.findIndex((item) => {
          return item.fieldcode === "filesnum" && item.colstype === 1;
        });
        if (index > -1) {
          data.uiTableViewDOs.splice(index, 1);
        }
      }
      return data;
    });
}
/**
 * 档案列表获取门类配置
 * @param {Object} param
 * @param {String} param.storage - 库类型
 * @param {String} param.archtypeid - 档案门类
 * @param {String} [param.projectId] - 项目id
 * @returns {Object}
 */
export function httpGetArchTypeData({ storage, archtypeid, projectId }) {
  return http
    .post("arrange/using/repodoc/load", {
      archtypeid,
      // 是否利用库，true是 false否
      paramscenetype: storage === ARCH_STORAGE.UTILIZ.CODE,
      // 项目id
      projectid: projectId,
    })
    .then(({ data }) => data);
}
// 档案列表加载主列表数据
export function httpLoadArchListData({
  storage,
  page,
  limit,
  isTreeAll = 0, // 是否目录树“全部”（功能已取消）
  pathcode, // 分类树code（功能已取消）
  creater, // 整理人过滤（功能已取消）
  archtypeid, // 门类id
  objecttype, // 档案对象类型
  nodeid, // 当前目录树节点id
  groupnodeid, // 当前目录树节点所属分组节点id
  groupconditions = [], // 当前目录树节点的分组条件
  cattype, // 当前目录树节点类型
  filterConditions = [], // defaultviewconditions 默认视图检索条件
  searchconditions = [],
  viewconditions = [],
  status, // 档案状态
  managestatus, // 档案管理状态
  fillingdepartment, // 归档部门过滤
  orderfield, // 排序字段
  ordertype, // 排序类型
  conditionType, //过滤条件连接符，AND为所有，OR为任意一条，默认为AND
  projectId = "", // 项目ID
}) {
  // 按照档案对象（ARCH_OBJTYPE）的枚举，匹配不同的接口地址
  let urls = {
    DOC: "/arrange/using/repodoc/docs", // 单文件、盒-全部文件、卷-全部文件
    BOX: "/arrange/using/repodoc/boxes", // 盒列表
    INBOX: "/arrange/using/repodoc/innerbox", // 盒内文件列表
    UNBOX: "/arrange/using/repodoc/unbox", // 未装盒文件列表
    VOL: "/arrange/using/repodoc/vols", // 案卷列表
    INVOL: "/arrange/using/repodoc/innervol", // 卷内文件列表
    UNVOL: "/arrange/using/repodoc/unvol", // 散文件列表
  };
  // 特殊参数--回收站展示的档案状态
  let historystatus = undefined;
  // 接收库回收站，只展示“待检查”、“已检查”、“需整理”状态的数据
  if (
    status === ARCH_LIST_TAB.DELETED.CODE &&
    storage === ARCH_STORAGE.COLLECT.CODE
  ) {
    historystatus = [
      ARCH_LIST_TAB.TOCHECK.CODE,
      ARCH_LIST_TAB.CHECKED.CODE,
      ARCH_LIST_TAB.TOARRANGE.CODE,
    ];
  }
  // 整理库回收站，只展示“待整理”、“已整理”、“不归档”状态的数据
  if (
    status === ARCH_LIST_TAB.DELETED.CODE &&
    storage === ARCH_STORAGE.ARRANGE.CODE
  ) {
    historystatus = [
      ARCH_LIST_TAB.TOCOLLECT.CODE,
      ARCH_LIST_TAB.COLLECTED.CODE,
      ARCH_LIST_TAB.UNSAVED.CODE,
    ];
  }
  // 综合库回收站，只展示“已归档”状态的数据
  if (
    status === ARCH_LIST_TAB.DELETED.CODE &&
    storage === ARCH_STORAGE.PRESERVE.CODE
  ) {
    historystatus = [ARCH_LIST_TAB.SAVED.CODE];
  }
  // 利用库回收站，只展示“已归档”状态的数据
  if (
    status === ARCH_LIST_TAB.DELETED.CODE &&
    storage === ARCH_STORAGE.UTILIZ.CODE
  ) {
    historystatus = [ARCH_LIST_TAB.SAVED.CODE];
  }
  return http.post(urls[ARCH_OBJTYPE.getKeyFromValue(objecttype)], {
    page,
    limit,
    isTreeAll,
    pathcode,
    fillingdepartment,
    creater,
    archtypeid,
    objecttype,
    nodeid,
    groupnodeid,
    groupconditions,
    cattype,
    defaultviewconditions: filterConditions,
    searchconditions,
    viewconditions,
    managestatus,
    orderfield,
    ordertype,
    conditionType, //过滤条件连接符，AND为所有，OR为任意一条，默认为AND
    historystatus: historystatus,
    status:
      +status === ARCH_LIST_TAB.USED.CODE ? ARCH_STATUS.SAVED.TYPE : status,
    paramscenetype: storage === ARCH_STORAGE.UTILIZ.CODE,
    projectid: projectId,
  });
}
// 档案列表加载子列表数据
export function httpLoadInnerArchListData({
  storage,
  page = 1,
  limit = 9999,
  archtypeid, // 门类id
  objecttype, // 主表档案对象类型
  searchconditions = [],
  status, // 档案状态
  orderfield, // 排序字段
  ordertype, // 排序类型
  volid,
  boxid,
}) {
  // 按照主表档案对象（ARCH_OBJTYPE）的枚举，匹配不同的接口地址
  let urls = {
    BOX: "/arrange/using/repodoc/innerbox", // 盒内文件列表
    VOL: "/arrange/using/repodoc/innervol", // 卷内文件列表
  };
  return http.post(urls[ARCH_OBJTYPE.getKeyFromValue(objecttype)], {
    page,
    limit,
    archtypeid,
    objecttype: 3, // 子表档案对象固定为“文件”
    searchconditions,
    orderfield,
    ordertype,
    volid,
    boxid,
    status:
      +status === ARCH_LIST_TAB.USED.CODE ? ARCH_STATUS.SAVED.TYPE : status,
    paramscenetype: storage === ARCH_STORAGE.UTILIZ.CODE,
  });
}
export function httpModifyMetadata(data) {
  return http.post("arrange/repo/modify/data/update", data);
}

/**
 * 文件拆盒
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.dataids -- 需要拆盒的文件集合
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpBoxRemove(data) {
  return http.post("arrange/repo/box/remove", data);
}
/**
 * 加载档案分组信息
 * @param data
 * @param {String} data.archivetypeid -- 门类id
 * @param {Array} data.year -- 年度
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpArchAdjustGroup(data) {
  return http.post("arrange/repo/arch/adjust/group/load", data);
}
/**
 * 保存档案分组信息
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.dataids -- 需要拆盒的文件集合
 * @param {Object} data.fields -- 需要保存的分组信息
 * @param {Number} data.objecttype -- 档案类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpArchSaveAdjustGroup(data) {
  return http.post("arrange/repo/arch/adjust/group/save", data);
}
/**
 * 装盒校验
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.dataids -- 需要装盒的文件集合
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpBoxPutVerify(data) {
  return http.post("arrange/repo/box/put/verify", data);
}
/**
 * 新建盒信息初始化
 * @param data
 * @param {String} data.id -- 门类id
 * @param {String} data.archruleid -- 门类配置-整理规则id
 * @param {Array} data.groupconditions -- 盒号分组条件
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpBoxPutLoad(data) {
  return http.post("arrange/repo/box/put/preload", data);
}
/**
 * 获得可装入盒的列表
 * @param data
 * @param {String} data.id -- 门类id
 * @param {String} data.archruleid -- 门类配置-整理规则id
 * @param {Array} data.groupconditions -- 盒号分组条件
 * @param {String} data.excludeid -- 返回值中排除的盒id，允许空
 * @param {String} data.fillingdepartment -- 归档部门
 * @param {Number} data.status -- 状态
 * @param {String} data.key -- 查询条件关键字
 * @param {Number} data.limit -- 盒号分组条件
 * @param {Number} data.page -- 需要拆盒的文件集合
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpBoxPutList(data) {
  return http.post("arrange/repo/box/put/list", data);
}
/**
 * 装盒
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.dataids -- 需要装盒的文件集合
 * @param {String} data.archruleid -- 门类配置-整理规则id
 * @param {String} data.boxcode -- 盒号
 * @param {String} data.backwidth -- 卷盒规格
 * @param {String} data.dataid -- 加入已有盒的id
 * @param {Number} data.newbox -- 是否装入新盒 1是0否
 * @param {Number} data.status -- 状态
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpBoxPut(data) {
  return http.post("arrange/repo/box/put", data);
}

export function httpCheckModifyData(data) {
  return http.post("arrange/repo/modify/data/check", data);
}
export function httpCheckModifyDataInOfflineImport(data) {
  return http.post("offline/import/batch/check/metadata", data);
}

/**
 * 同组编号-页面初始化校验
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.dataids -- 选择文件集合
 * @param {Number} data.objecttype -- 档案类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpArchCodeVerify(data) {
  return http.post("arrange/repo/arch/archcode/samegroup/verify", data);
}
/**
 * 获取编号权的接口
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.dataids -- 选择文件集合
 * @param {Number} data.objecttype -- 档案类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpArchCodeHasNumberPermit(data) {
  return http.post("arrange/repo/arch/archcode/get/hasnumberpermit", data);
}
/**
 * 同组编号-页面初始化
 * @param {Object} data
 * @param {String} data.id -- 门类id
 * @param {Array} data.dataids -- 选择文件集合
 * @param {Number} data.objecttype -- 档案类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpArchCodePresave(data) {
  return http.post("arrange/repo/arch/archcode/samegroup/presave", data);
}
/**
 * 同组编号-保存结果
 * @param {Object} data
 * @param storage --库类型
 * @param {String} data.id -- 门类id
 * @param {Array} data.dataids -- 选择文件集合
 * @param {Number} data.objecttype -- 档案类型
 * @param {Number} data.startcode -- 起始件号
 * @param {Number} data.tocollected -- 是否转为已整理
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpArchCodeSave(data, storage) {
  if (storage === ARCH_STORAGE.COLLECT.CODE) {
    return http.post("arrange/collection/samegroup/save", data);
  }
  return http.post("arrange/repo/arch/archcode/samegroup/save", data);
}
/**
 * 同组编号-刷新档号
 * @param {Object} data
 * @param {String} data.id -- 门类id
 * @param {Array} data.dataids -- 选择文件集合
 * @param {Number} data.objecttype -- 档案类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpArchCodeUpdate(data) {
  return http.post("arrange/repo/arch/archcode/update", data);
}
/**
 * 合并组件-加载合并组件电子文集即归档信息
 * @param {Object} data
 * @param {String} data.archtypeid -- 门类id
 * @param {Array} data.dataids -- 选择文件集合
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpOptArchivesLoad(data) {
  return http.post("arrange/repo/opt/archives/load", data);
}
/**
 * 合并组件-保存合并组件信息
 * @param {Object} data
 * @param {String} data.archtypeid -- 门类id
 * @param {String} data.dataid -- 保留的档案id
 * @param {Array} data.dataids -- 选择文件集合
 * @param {Array} data.edocids -- 保留的电子文件id集合
 * @param {Object} data.entityArchive -- 完善的信息
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpOptArchivesMerge(data) {
  return http.post("arrange/repo/opt/archives/merge", data);
}

/**
 * 获取导出字段
 * @param {String} archtypeid -- 门类id
 * @param {Number} objecttype --  档案类型
 * @returns {Promise<AxiosResponse<any>>}
 * */
export function httpGetExportFields({ archtypeid, objecttype }) {
  return http.post("arrange/export/v2/export/fields", {
    archtypeid,
    objecttype,
  });
}
/**
 * 准备导出文件
 * @param {Object} data
 * */
export function httpPrepareExportFiles(data, status) {
  let url =
    status === ARCH_STATUS.USING.TYPE
      ? "arrange/using/v2/generate/file"
      : "arrange/export/v2/generate/file";
  return http.post(url, data);
}
/**
 * 文件复制到本门类
 * @param {Object} data
 * @param {String} data.archtypeid -- 门类id
 * @param {Array} data.idList -- 选择文件集合
 * @param {String} data.contenttype -- 复制内容 1:条目 2：条目+电子文件
 * @param {String} data.objecttype -- 文件类型
 * @param {String} data.status -- 文件状态
 * @param {String} data.storage -- 库名
 * @param {String} data.tableid -- 原文件所在表名
 * @param {String} data.titleset -- 题名处理 1：添加前缀“（复制）” 2：保持不变
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpOptCopy(data) {
  return http.post("arrange/repo/opt/copy", data);
}
/**
 * 获得与tableid表头对于的表
 * @param {Object} data
 * @param {String} data.fondsid -- 全宗id
 * @param {String} data.tableid -- 原文件所在表名
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpFieldassoAll(data) {
  return http.post("setting/types/fieldasso/all", data);
}
/**
 * 复制或移动文件信息
 * @param {Object} data
 * @param {String} data.archtypeid -- 文件原门类id
 * @param {String} data.cleanarchcode -- 是否清除档号
 * @param {Array} data.docFieldassoList -- 文件级字段关系对应。没有对应方案时，传入此参数
 * @param {String} data.fieldassoid -- id
 * @param {String} data.force -- force
 * @param {Array} data.idList -- 文件ids
 * @param {String} data.objecttype -- 文件类型
 * @param {String} data.superobjecttype -- 父类档案类型
 * @param {String} data.opttype -- 操作类型（1:复制 2:移动）
 * @param {String} data.status -- 文件状态
 * @param {String} data.tableid -- 原文件所在表名
 * @param {String} data.targettableid -- 复制目标表名
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpOptCopyMove(data) {
  return http.post("arrange/repo/opt/copyormove", data);
}
/**
 * 获得表的字段信息
 * @param {String} tableid -- 原文件所在表名
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpFieldassoFields(tableid) {
  return http.post("setting/types/fieldasso/fields", { tableid: tableid });
}
/**
 * 电子文件下载时是否具有水印和电子章
 * @param {Object} data
 * @param {String} data.id -- 门类id
 * @param {Array} data.ids -- 选择文件集合
 * @param {Number} objecttype --  档案类型
 * @returns {Promise<AxiosResponse<any>>}*/
export function httpERecordAdjustDownloadType(data) {
  return http.post("arrange/repo/system/adjust/exist", data);
}
/**
 * 电子文件下载时是否具有水印和电子章
 * @param {String} id -- 门类id
 * @returns {Promise<AxiosResponse<any>>}*/
export function httpGetAttachTypeList({ id }) {
  return http.post("arrange/repo/setting/types/attach/list", { id });
}
/**
 * 校验批量打印电子文件
 * * @param {Object} data
 *  * @param status --档案状态
 *  * @param {String} data.archtypeid -- 门类id
 *  * @param {Array} data.ids -- 选择文件集合
 *  * @param {Number} data.objectType --  档案类型
 *  * @param {Array} data.type --  水印类型
 *  * @param {Array} data.attachtypes --  附件类型
 *  * @returns {Promise<AxiosResponse<any>>}*/
export function httpVerifyPrintRecord(data, status) {
  let url =
    ARCH_STATUS.USING.TYPE === status
      ? "arrange/using/record/verifyprint"
      : "system/arrange/record/verifyprint";
  return http.post(url, data);
}

/**
 * 获取门类的附件类型的数据
 * @param {String} id -- 门类id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpAttachList({ id }) {
  return http.post("setting/types/attach/list", { id });
}

/**
 * 电子文件下载时是否具有水印和电子章
 * @param {Object} data
 * @param {String} data.id -- 门类id
 * @param {Number} data.objecttype -- 档案类型
 * @param {Array<Object>} data.ids -- 档号id集合
 * @param {String} data.ids[].id -- 档号id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpAdjustExist(data, isUsing) {
  let url = isUsing
    ? "arrange/using/system/adjust/exist"
    : "arrange/repo/system/adjust/exist";
  return http.post(url, data);
}

/**
 * 批量下载电子文件时校验
 * @param {Object} data
 * @param {Array<String>} data.ids -- 档号id集合
 * @param {String} data.structure -- 目录结构: 0 档号 1 题名
 * @param {Number} data.type -- 电子文件类型
 * @param {Array<String>} data.attachtype -- 附件类型
 * @param {String} data.archName -- 门类名称
 * @param {String} data.archiveTypeId -- 门类id
 * @param {Number} data.objectType -- 对象类型
 * @param {Number} data.isfront -- 是否是前台下载
 * @param {Number} data.isallattachtype -- 是否为后台下载全部
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpVerifyDownload(data, status) {
  let url =
    status === ARCH_STATUS.USING.TYPE
      ? "arrange/using/record/batch/verify/download"
      : "common/record/batch/verify/download";
  return http.post(url, data);
}

/**
 * 导入文件
 * */
export function httpUploadFile(data) {
  return http.post("arrange/repo/system/batch/uploads", data);
}
/**
 * 导入ftp文件
 * */
export function httpUploadFtpFile(data) {
  return http.post("arrange/repo/system/batch/new/uploads", data);
}
/**获取可导入文件类型
 * @param {String} id -- 门类id
 * */
export function httpGetAttachExtensions({ id }) {
  return http.post("arrange/repo/setting/types/attach/extensions", { id });
}
/**
 * 删除导入附件
 * @param {Object} data
 * @param {Array} data.dataids -- 档案id
 * @param {String} data.id -- 门类id
 * @param {Number} data.objecttype -- 对象类型
 * */
export function httpDeleteUploadFile(data) {
  return http.post("arrange/repo/arch/batch/delete", data);
}
/**
 * 批量著录校验上传文件是否符合
 * @param {Object} data
 * @param {String} data.archiveTypeId -- 门类id
 * @param {Array} data.fields -- 选择传入的字段
 * @param {String} data.newFilename -- 文件后台存储名
 * @param {String} data.originalFilename -- 文件来源名
 * @param {Number} data.status -- 文件状态
 * @param {Number} data.objecttype -- 文件类型
 * @param {String} data.superDataId -- 导入有父级的文件时的父档案id
 * @param {String} data.superObjectType -- 父档案类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpCheckUploadFileData(data) {
  return http.post("arrange/import/v2/check/upload/file/data", data);
}
/**
 * 批量著录校验上传文件是否符合
 * @param {Object} data
 * @param {String} data.archiveTypeId -- 门类id
 * @param {String} data.archiveTypeName -- 门类名
 * @param {Array} data.fields -- 选择传入的字段
 * @param {String} data.filename -- 文件后台存储名
 * @param {Number} data.status -- 文件状态
 * @param {Number} data.objectType -- 文件类型
 * @param {Number} data.totalSize -- 文件数
 * @param {Number} data.importType -- 后台日志类型 1新增 2更新
 * @param {String} data.importKey -- 时间戳
 * @param {String} data.fillingdeptname -- 归档部门
 * @param {String} data.superDataId -- 导入有父级的文件时的父档案id
 * @param {String} data.superObjectType -- 父档案类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpImportData(data) {
  return http.post("arrange/import/v2/import/data", data);
}
/**
 * 批量著录查询文件已导入条数
 */
export function httpImportProgress(importKey) {
  const getParam = { "x-access-token": store.state?.accessToken };
  const query = `${getQueryStr(getParam)}`;
  return http.get(
    `arrange/import/v2/import/data/progress/${importKey}?${query}`
  );
}
/**
 * 批量删除档案
 * @param {Object} data
 * @param {Array} data.dataids -- 档案id
 * @param {String} data.id -- 门类id
 * @param {Number} data.objecttype -- 对象类型
 *
 * arrange/repo/recycle/delete回收站删除
 * arrange/using/recycle/delete 利用库回收站删除
 * */
export function httpBatchDeleteArchive(data, status, storage) {
  let url = "";
  if (storage === ARCH_STORAGE.UTILIZ.CODE) {
    if (status === ARCH_STATUS.DELETED.TYPE) {
      url = "arrange/using/recycle/delete"; //利用库回收站删除
    } else {
      url = "arrange/using/arch/batch/delete"; //利用库普通删除删除
    }
  } else {
    if (status === ARCH_STATUS.DELETED.TYPE) {
      url = "arrange/repo/recycle/delete"; //管理库回收站删除
    } else {
      url = "arrange/repo/arch/batch/delete"; //管理库普通删除
    }
  }
  return http.post(url, data);
}

/**
 * 获取待汇总字段
 * @param {String} id 门类id
 * @param {Number} objecttype 对象类型
 */
export function httpFieldsSummary({ id, objecttype }) {
  return http.post("arrange/repo/arch/fields/summary", { id, objecttype });
}

/**
 * 汇总接口
 * @param {String} data.id 门类id
 * @param {Number} data.objecttype 对象类型
 * @param {Array<Object>} data.dataids 汇总的条目id
 * @param {Array<Object>} data.fields 汇总的字段id
 */
export function httpArchSummary(data) {
  return http.post("arrange/repo/arch/summary", data);
}

/**
 * 盒内汇总接口
 * @param {String} data.id 门类id
 * @param {Array<Object>} data.dataids 汇总的条目id
 */
export function httpBoxSummary(data) {
  return http.post("arrange/repo/box/summary", data);
}
/**
 * 校验档号接口
 * @param {Object} data
 * @param {Array} data.boxids -- 盒id集合
 * @param {Array} data.docids -- 文件id集合
 * @param {String} data.id  -- 门类id
 * @param {Array} data.volids  -- 案卷id集合
 * */
export function httpCheckArchCode(data) {
  return http.post("arrange/repo/arch/flow/checkarchcode", data);
}
/**
 * 整理完毕
 * @param {Object} data
 * @param {Array} data.boxids -- 盒id集合
 * @param {Array} data.docids -- 文件id集合
 * @param {String} data.id  -- 门类id
 * @param {Array} data.volids  -- 案卷id集合
 * */
export function httpArchToCollected(data) {
  return http.post("arrange/repo/arch/flow/tocollected", data);
}
/**
 * 批量装盒校验
 * @param data
 * @param {String} data.archtypeid -- 门类id
 * @param {Array} data.archIds -- 需要装盒的文件集合
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpBoxPutListVerify(data) {
  return http.post("arrange/repo/box/put/box/list/verify", data);
}
/**
 * 批量装盒校验
 * @param data
 * @param {String} data.archtypeid -- 门类id
 * @param {Array} data.dto -- 盒的信息
 * @param {Array} data.groupconditions -- 分组条件
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpBoxPutBoxList(data) {
  return http.post("arrange/repo/box/put/box/list", data);
}
/**
 * 组卷获取案卷列表接口
 * @param {Object} data
 * @param {String} data.excludedataid -- 排除的案卷id
 * @param {String} data.fillingdepartment -- 归档部门
 * @param {String} data.id -- 门类id
 * @param {String} data.key -- 检索信息
 * @param {Number} data.limit -- 每页条数
 * @param {Number} data.page -- 页码
 * @param {Number} data.status -- 库状态
 * */
export function httpGetOptPutList(data) {
  return http.post("arrange/repo/vol/put/list", data);
}
/**
 * 组卷保存接口
 * @param {{dataid: *, dataids: {id: *}[], id: string, status: number}} data
 * @param {String} data.dataid -- 案卷id
 * @param {Array} data.dataids -- 档案id
 * @param {String} data.id -- 门类id
 * @param {String} data.status -- 库状态
 * */
export function httpOptPutIn(data) {
  return http.post("arrange/repo/vol/put", data);
}
/**
 * 卷内调序文件列表
 * @param {Object} data
 * @param {String} data.dataid -- 案卷id
 * @param {String} data.id -- 门类id
 * @param {Number} data.limit -- 门类id
 * @param {Array} data.orderList -- 门类id
 * @param {Number} data.page -- 门类id
 * */
export function httpGetDocOrderList(data) {
  return http.post("arrange/repo/vol/docorder/list", data);
}
/**
 * 卷内调序
 * @param {Object} data
 * @param {String} data.dataid -- 案卷id
 * @param {Array} data.dataids -- 案卷id
 * @param {String} data.id -- 门类id
 * */
export function httpInVolDocorder(data) {
  return http.post("arrange/repo/vol/docorder", data);
}
/**
 * 更新卷内页号
 * @param {Object} data
 * @param {String} data.objecttype -- 对象类型
 * @param {Array} data.dataids -- 案卷id
 * @param {String} data.id -- 门类id
 * */
export function httpUpdatePageNo(data) {
  return http.post("arrange/repo/vol/update/pageno", data);
}

/**
 * 组卷
 * @param {String} data.id 门类id
 * @param {Number} data.status 档案状态
 * @param {Array<Object>} data.dataids 档案id
 */
export function httpVolumePutNew(data) {
  return http.post("arrange/repo/volume/putnew", data);
}

/**
 * 初始化组卷
 * @param {String} data.id 门类id
 * @param {Number} data.objecttype 档案类型
 * @param {Number} data.pagenum 每卷标准页数，默认是100
 * @param {Array<Object>} data.dataids 汇总的条目id
 */
export function httpInitVolPut(data) {
  return http.post("arrange/repo/volume/initvolput", data);
}

/**
 * 批量组卷（选中文件条目自动组成新案卷）
 * @param {String} data.id 门类id
 * @param {Number} data.objecttype 档案类型
 * @param {Number} data.status 档案状态
 * @param {Array<Object>} data.volGroupList 档案组卷列表
 * @param {Array<Object>} data.dataids 档案id
 */
export function httpBatchPutVol(data) {
  return http.post("arrange/repo/volume/batchputvol", data);
}

/**
 * 打散案卷（整理库）
 * @param {String} data.id 门类id
 * @param {Number} data.objecttype 档案类型
 * @param {Array<Object>} data.dataids 档案id
 */
export function httpVolBreakup(data) {
  return http.post("arrange/repo/vol/breakup", data);
}

/**
 * 打散案卷（接收库）
 * @param {String} data.id 门类id
 * @param {Number} data.objecttype 档案类型
 * @param {Array<Object>} data.dataids 档案id
 */
export function httpCollectionVolBreakup(data) {
  return http.post("arrange/collection/vol/breakup", data);
}

/**
 * 回收站内-还原
 * @param {String} data.id 门类id
 * @param {Number} data.objecttype 档案类型
 * @param {Array<Object>} data.dataids 档案id
 */
export function httpRecycleRecover(data) {
  return http.post("arrange/repo/recycle/recover", data);
}

/**
 * 回收站内-还原（利用库）
 * @param {String} data.id 门类id
 * @param {Number} data.objecttype 档案类型
 * @param {Array<Object>} data.dataids 档案id
 */
export function httpUsingRecycleRecover(data) {
  return http.post("arrange/using/recycle/recover", data);
}
/**
 * 关联档案设置信息接口
 * @param data
 * @param {String} data.archtypeid -- 门类id
 * @param {String} data.objecttype -- 档案类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpRelationForward(data) {
  return http.post("arrange/repo/relation/forward", data);
}
/**
 * 获得文件附件信息
 * @param data
 * @param isUsing
 * @param {String} data.archtypeid -- 门类id
 * @param {String} data.id -- 档案id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpRepoSystemList(data, isUsing = false) {
  let url = isUsing ? "arrange/using/system/list" : "arrange/repo/system/list";
  return http.post(url, data);
}
/**
 * 获得文件全程管理日志
 * @param data
 * @param {String} data.tableId -- 表id
 * @param {String} data.archId -- 档案id
 * @param {String} data.maintainType -- 程管理根据:形成，整理，保存，利用进行拆分
 * @param {String} data.orderStr -- 排序字段
 * @param {String} data.orderType -- 排序字段烈性
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpRepoLogList(data) {
  return http.post("arrange/repo/log/list", data);
}
/**
 * 获取档号
 * @param data
 * @param {String} data.id -- 门类id
 * @param {String} data.dataid -- 档案id
 * @param {String} data.objecttype -- 档案类型
 * @param {String} data.ishandle -- 是否是手动，1手动，0自动
 * @param {String} data.fields -- 填写的信息
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpRepoGenerateArchCode(data) {
  return http.post("arrange/repo/arch/generate/archcode", data);
}
/**
 * 保存档案
 * @param data
 * @param {String} data.id -- 门类id
 * @param {String} data.dataid -- 档案id
 * @param {String} data.objecttype -- 档案类型
 * @param {String} data.from -- 1手工著录，2条目保存 3插录 4追加 5查看
 * @param {String} data.opt -- 档案状态
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpRepoArchSave(data) {
  return http.post("arrange/repo/arch/save", data);
}
/**
 * 校验文件是否可追加
 * @param data
 * @param {String} data.id -- 门类id
 * @param {String} data.dataid -- 追加的档案id
 * @param {String} data.objecttype -- 档案类型
 * @param {String} data.superdataid -- 父档案id
 * @param {String} data.superobjecttype -- 父档案类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpArchVerifyAppend(data) {
  return http.post("arrange/repo/arch/verify/append", data);
}
/**
 * 校验文件是否可插录
 * @param data
 * @param {String} data.id -- 门类id
 * @param {String} data.dataid -- 追加的档案id
 * @param {String} data.objecttype -- 档案类型
 * @param {String} data.superdataid -- 父档案id
 * @param {String} data.superobjecttype -- 父档案类型
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpArchVerifyCutin(data) {
  return http.post("arrange/repo/arch/verify/cutin", data);
}
/**
 * 电子文件重新转换
 * @param {Array} ids -- 电子文件ids
 * @param {String} archTypeId -- 门类Id
 * @param isUsing
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpRepoAgainTransfer({ ids, archTypeId }, isUsing) {
  let url = isUsing
    ? "arrange/using/system/again/transfer"
    : "arrange/repo/system/again/transfer";
  return http.post(url, { idlist: ids, archtypeid: archTypeId });
}
/**
 * 电子文件删除
 * @param data
 * @param {String} data.fondsid -- 全宗id
 * @param {Array} data.ids -- 电子文件ids
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpSystemDelete(data) {
  return http.post("arrange/repo/system/delete", data);
}
/**
 * 电子文件验证
 * @param data
 * @param {String} data.fondsid -- 全宗id
 * @param {String} data.id -- 电子文件ids
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpSystemVerifySignature(data) {
  return http.post("arrange/repo/system/verify/signature", data);
}
/**
 * 电子文件排序
 * @param data
 * @param {String} data.archtypeid -- 门类id
 * @param {Array} data.ids -- 电子文件ids
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpSystemOrder(data, isUsing) {
  let url = isUsing
    ? "arrange/using/system/order"
    : "arrange/repo/system/order";
  return http.post(url, data);
}
/**
 * 文件关联档案门类信息
 * @param {String} dataid -- 档案id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpRelationArchtypeList(dataid) {
  return http.post("arrange/repo/relation/archtypelist", { dataid: dataid });
}
/**
 * 文件关联档案门类信息
 * @param data
 * @param isUsing -- 是否是利用库
 * @param {String} data.archtypeid -- 门类id
 * @param {String} data.dataid -- 档案id
 * @param {String} data.objecttype -- 档案类型
 * @param {String} data.referarchtypeid -- 关联档案id
 * @param {String} data.relationtype -- 关联档案方式类别
 */
export function httpRelationList(data, isUsing = false) {
  let url = isUsing ? "arrange/using/list" : "arrange/repo/relation/list";
  return http.post(url, data);
}
/**
 * 文件关联档案门类信息
 * @param {String} id -- 关联id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpRelationDelete(id) {
  return http.post("arrange/repo/relation/deleterelation", { id: id });
}
/**
 * 文件添加关联档案（元数据）
 * @param {Object} data
 * @param {String} data.archtypeid -- 门类id
 * @param {String} data.dataid -- 档案id
 * @param {String} data.objecttype -- 档案类型
 * @param {String} data.relationfieldid -- 关联元数据id
 * @param {String} data.relationidentity -- 关联标识
 * @param {String} data.relationtype -- 关联档案方式类别
 * @param {Array} data.referdataidlist -- 关联档案id集合
 * @param {String} data.referarchtypeid -- 关联档案门类集合
 * @param {Number} data.referobjecttype -- 关联档案文件类型
 */
export function httpRelationAdd(data) {
  return http.post("arrange/repo/relation/addrelation", data);
}

/**
 * 从案卷中拆离接口
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.dataids -- 需要拆离的卷内文件集合
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpVolRemove(data) {
  return http.post("arrange/repo/vol/remove", data);
}

/**
 * 从案卷中拆离接口
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.dataids -- 需要拆离的卷内文件集合
 * @param {Number} data.status -- 状态
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httCollectionVolRemove(data) {
  return http.post("arrange/collection/vol/remove", data);
}

/**
 * 转待整理接口
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.docids -- 转待整理文件集合
 * @param {Array} data.boxids -- 转待整理盒集合
 * @param {Array} data.volids -- 转待整理案卷集合
 * @param {Number} data.cleanarchcode -- 清空档号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpToUncollected(data) {
  return http.post("arrange/repo/arch/flow/touncollected", data);
}

/**
 * 转已检查接口
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.docids -- 转待整理文件集合
 * @param {Array} data.boxids -- 转待整理盒集合
 * @param {Array} data.volids -- 转待整理案卷集合
 * @param {Number} data.cleanarchcode -- 清空档号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpToChecked(data) {
  return http.post("arrange/repo/arch/flow/tochecked", data);
}

/**
 * 转需整理接口
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.docids -- 转待整理文件集合
 * @param {Array} data.boxids -- 转待整理盒集合
 * @param {Array} data.volids -- 转待整理案卷集合
 * @param {Number} data.cleanarchcode -- 清空档号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpToToArrange(data) {
  return http.post("arrange/repo/arch/flow/totoarrange", data);
}

/**
 * 转待检查接口
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.docids -- 转待整理文件集合
 * @param {Array} data.boxids -- 转待整理盒集合
 * @param {Array} data.volids -- 转待整理案卷集合
 * @param {Number} data.cleanarchcode -- 清空档号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpToToCheck(data) {
  return http.post("arrange/repo/arch/flow/totocheck", data);
}

// 批量挂接————获取当前门类下附件上传限制
export async function httpGetUploadAttachLimit({ id = "" } = {}) {
  const [
    {
      data: { filesize = 0 },
    },
    {
      data: { allowextension = "" },
    },
  ] = await Promise.all([
    httpGetFileSizeLimit(),
    httpGetAttachExtensions({ id }),
  ]);
  return {
    accept: allowextension,
    maxlength: filesize,
  };
}
/**
 * @description: 批量挂接——获取已选择的档案数据
 * @param data
 * @param {Array} data.archids 已选档案的id列表
 * @param {String} data.tableid 已选档案的表名
 * @return {Array} list 档案列表
 */
export function httpGetMatchDocs({ archids = [], tableid = "" } = {}) {
  return http
    .post("arrange/repo/system/arch/list", { archids, tableid })
    .then(({ data: { list = [] } }) => list);
}
// 批量挂接——文件上传（分片）
export function httpUploadFileChunk(param = {}) {
  return http.post("arrange/repo/system/upload/chunk", param);
}
// 批量挂接——文件上传（不分片）
export function httpUploadFileNoChunk(param = {}) {
  return http.post("arrange/repo/system/uploads", param);
}
// 批量挂接——向后台发送请求校验文件是否存在或者不完整,存在且完整实现秒传效果
export function httpUploadFileCheckExist(param = {}) {
  return http.post("arrange/repo/system/check/exist", param);
}
// 批量挂接——向后台发送请求询问该文件是否存在或者完整
export function httpUploadFileCheckComplete(param = {}) {
  return http.post("arrange/repo/system/check/complete", param);
}
// 批量挂接——向后台发送请求告诉可以将所有的文件块合并成文件了
export function httpUploadFileMerge(param = {}) {
  return http.post("arrange/repo/system/merge", param);
}
/**
 * @description: 批量挂接——ftp文件上传
 * @param {*} data
 * @return {*}
 */
export function httpUploadFTPAttach(data = {}) {
  return http.post("arrange/ftp/system/uploads", data);
}
// 批量挂接——校验电子文件是否重复挂接
export function httpCheckRepeatEle(docs = []) {
  return http.post("arrange/repo/system/batch/check/namerepeat", docs);
}
//批量挂接——电子文件挂接
export function httpUploadAttach({ id = "", electric = [] } = {}) {
  let newArch = electric.map(({ path, filename, tableId }) => ({
    filePath: path,
    filename,
    id,
    tableId,
  }));
  return Promise.all(
    newArch.map((v) => http.post("arrange/repo/system/uploads/new", v))
  );
}

//  删除已挂接的电子文件
export function httpdeleteMountEle(data = {}) {
  return http.post("arrange/repo/system/delete", data);
}
/**
 * 获取档案的个性配置详情接口
 * @param data
 * @param {String} data.dataid 已选档案的id
 * @param {String} data.id 门类id
 * @param {Number} data.objecttype 对象类型
 */
export function httpGetAuthDetail(data) {
  return http.post("arrange/repo/modify/auth/detail", data);
}
/**
 * 获取权限配置接口
 * @param data
 * @param {String} data.id  门类id
 * */
export function httpGetAuth(data) {
  return http.post("arrange/repo/setting/types/auth/load", data);
}
/**
 * 汇总案卷权限并保存
 * @param data
 * @param {Number} data.authtype  门类id
 * @param {Number} data.authtypefilingdepartment  门类id
 * @param {Number} data.authtypehandlingdepartment  门类id
 * @param {Number} data.authtypehandlinguser  门类id
 * @param {Number} data.copysend  门类id
 * @param {Array} data.dataids  门类id
 * @param {Number} data.download  门类id
 * @param {Number} data.downloadsource  门类id
 * @param {Number} data.excerpt  门类id
 * @param {Number} data.extendstype  门类id
 * @param {String} data.id  门类id
 * @param {Number} data.lendout  门类id
 * @param {Number} data.liveread  门类id
 * @param {Number} data.objecttype  门类id
 * @param {Number} data.onlinebrowser  门类id
 * @param {Number} data.onlineprint  门类id
 * @param {Number} data.usefilingdepartment  门类id
 * @param {Number} data.usehandlingdepartment  门类id
 * @param {Number} data.usehandlinguser  门类id
 * @param {Number} data.usescope  门类id
 */
export function httpSummaryAuth(data) {
  return http.post("arrange/repo/modify/auth/summary", data);
}
/**
 * 汇总案卷权限并保存
 * @param data
 * @param {Number} data.authtype  门类id
 * @param {Number} data.authtypefilingdepartment  门类id
 * @param {Number} data.authtypehandlingdepartment  门类id
 * @param {Number} data.authtypehandlinguser  门类id
 * @param {Number} data.copysend  门类id
 * @param {Array} data.dataids  门类id
 * @param {Number} data.download  门类id
 * @param {Number} data.downloadsource  门类id
 * @param {Number} data.excerpt  门类id
 * @param {Number} data.extendstype  门类id
 * @param {String} data.id  门类id
 * @param {Number} data.lendout  门类id
 * @param {Number} data.liveread  门类id
 * @param {Number} data.objecttype  门类id
 * @param {Number} data.onlinebrowser  门类id
 * @param {Number} data.onlineprint  门类id
 * @param {Number} data.usefilingdepartment  门类id
 * @param {Number} data.usehandlingdepartment  门类id
 * @param {Number} data.usehandlinguser  门类id
 * @param {Number} data.usescope  门类id
 */
export function httpSaveAuth(data) {
  return http.post("arrange/repo/modify/auth/save", data);
}
/**
 *同分类调件/卷-页面校验
 * @param data
 * @param {String} data.dataid  档案id
 * @param {String} data.id  门类id
 * @param {Number} data.objecttype  对象类型
 * */
export function httpAdjustSameGroupPresave(data) {
  return http.post("arrange/repo/arch/adjust/samegroup/presave", data);
}
/*下载文件校验*/
export function httpVerifyRecordDownload(data, isUsing) {
  let url = isUsing
    ? "arrange/using/record/verify/download"
    : "common/record/verify/download";
  return http.post(url, data);
}
/*个人原文区上传*/
export function httpUploadFtp(data) {
  return http.post("arrange/repo/system/uploadftp", data);
}
/*获取可上传文件类型*/
export function httpGetUploadType({ id }) {
  return http.post("arrange/repo/setting/types/attach/extensions", { id });
}
/**
 * 校验文件是否可调件
 * @param data
 * @param {String} data.id  门类id
 * @param {String} data.dataid  文件id
 * @param {String} data.objecttype  档案类型
 * */
export function httpVerifyAdjustPiece(data) {
  return http.post("arrange/repo/arch/adjust/verify", data);
}
/**
 * 校验文件是否可调件
 * @param data
 * @param {String} data.id  门类id
 * @param {String} data.dataid  文件id
 * @param {String} data.objecttype  档案类型
 * @param {String} data.fields  保存的信息
 * */
export function httpSaveAdjustPiece(data) {
  return http.post("arrange/repo/arch/adjust/save", data);
}
/**
 * 获取利用库档案详情
 *
 * @param {object} data - 参数对象.
 * @param {String} data.id - 门类id
 * @param {String} data.objecttype - 档案类型
 * @param {String} data.dataid - 档案id
 */
export function httpUsingArchiveLoad(data) {
  return http.post("arrange/using/archive/load", data);
}
/**
 * 同分类排序
 * @param {object} data - 参数对象.
 * @param {Array} data.dataids - 门类id
 * @param {String} data.id - 档案类型
 * @param {Number} data.objecttype - 档案类型
 * @param {Number} data.startcode - 档案类型
 * */
export function httpSameGroupSave(data) {
  return http.post("arrange/repo/arch/adjust/samegroup/save", data);
}
/**
 * 获取表格自定义信息
 * @param data
 * @param {String} data.archtypeid - 门类id
 * @param {Number} data.objecttype - 对象类型
 * @param {String} data.repotype - 库类型
 */
export function httpUiTableViewLoad(data) {
  return http.post("arrange/repo/uitableview/load", data);
}
/**
 * 修改表格信息接口
 * @param data
 * @param {String} data.archtypeid - 门类id
 * @param {Number} data.objecttype - 对象类型
 * @param {String} data.repotype - 库类型
 * @param {Number} data.pagesize - 每页显示条目数
 * @param {Numebr} data.specification - 字号规格
 * @param {Array} data.uiTableViewDOs - 表格自定义列表
 */
export function httpUiTableViewUpdate(data) {
  return http.post("arrange/repo/uitableview/update", data);
}
/**
 * 转其他人、调整整理人接口
 * @param data
 * @param {Array} data.dataids - 档案ID列表
 * @param {String} data.id - 档案ID
 * @param {Number} data.objecttype - 档案类型
 * @param {String} data.tableid - 表名
 * @param {String} data.userid - 用户ID
 */
export function httpModifyCreater(data) {
  return http.post("arrange/repo/modify/creater", data);
}
/**
 * 转档案室整理接口
 * @param data
 * @param {Array} data.dataids - 档案ID列表
 * @param {String} data.id - 档案ID
 * @param {Number} data.objecttype - 档案类型
 * @param {String} data.tableid - 表名
 */
export function httpModifyTransferArchives(data) {
  return http.post("arrange/repo/modify/transfer/archives", data);
}
/**
 * 转部门整理接口
 * @param data
 * @param {Array} data.dataids - 档案ID列表
 * @param {String} data.id - 档案ID
 * @param {Number} data.objecttype - 档案类型
 * @param {String} data.tableid - 表名
 */
export function httpModifyTransferDept(data) {
  return http.post("arrange/repo/modify/transfer/dept", data);
}
/**
 * 挂接文件到档案
 * @param data
 * @param {String} data.hash - 文件hash
 * @param {String} data.path - 文件路径
 * @param {String} data.fileName - 文件名
 * @param {String} data.tableId - f表
 * @param {String} data.archId - 档案id
 */
export function httpFileRelateArch(data) {
  return http.post("arrange/repo/system/file/relate/arch", data);
}
/**
 * 挂接完毕更新电子文件数量
 */
export function httpRefreshFileCount(data) {
  return http.post("arrange/repo/system/file/refresh/count", data);
  // return http.post("arrange/repo/system/file/savearchfilecount", data);
}
/**
 * 不归档转待整理接口
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.docids -- 转待整理文件集合
 * @param {Array} data.boxids -- 转待整理盒集合
 * @param {Array} data.volids -- 转待整理案卷集合
 * @param {Number} data.cleanarchcode -- 清空档号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpUnsavedToUncollected(data) {
  return http.post("arrange/repo/arch/flow/unsaved/touncollected", data);
}
/**
 * 移动到不归档库
 * @param data
 * @param {String} data.id -- 门类id
 * @param {Array} data.docids -- 转待整理文件集合
 * @param {Array} data.boxids -- 转待整理盒集合
 * @param {Array} data.volids -- 转待整理案卷集合
 * @param {Number} data.cleanarchcode -- 清空档号
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpMoveToUnSave(data) {
  return http.post("arrange/repo/arch/flow/tounsaved", data);
}

/**
 * [1-1] 获取档案视图记录列表接口
 *
 * @param {object} data - 参数对象.
 * @param {String} data.objecttype - 档案分类
 * @param {String} data.status - 档案状态
 * @param {String} data.archtypeid - 门类id
 * @param {String} data.managestatus - 用于区分个人/部门数据
 */
export function httpLoadFilterView({
  objecttype,
  status,
  archtypeid,
  managestatus,
}) {
  return http
    .post("arrange/view/recored/list", {
      archiveClassify: objecttype,
      archiveStatus: status,
      manageStatus: managestatus,
      archtypeid,
    })
    .then(({ data }) => data.list);
}

/**
 * 项目列表
 * @param {Object} data
 * @param {String} data.archtypeid -- 门类id
 * @param {Array} data.fillingDepartmentId -- 归档部门id
 * @param {String} data.filterProjectId -- 过滤项目id
 * @param {String} data.searchKeyword -- 检索关键字
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpLoadProjectArray({
  archtypeid,
  searchKeyword,
  fillingDepartmentId = [],
  filterProjectId,
  page,
  size,
}) {
  return http
    .post("arrange/project/manage/project/out/list", {
      archtypeid,
      searchType: ["title", "proarchcode"],
      searchKey: searchKeyword,
      fillingdepartmentid: Array.isArray(fillingDepartmentId)
        ? fillingDepartmentId
        : [fillingDepartmentId],
      filterProjectid: filterProjectId,
      page,
      size,
    })
    .then(({ data }) => data);
}

/**
 * 获取项目详情
 * @param {Object} data
 * @param {String} data.projectId -- 门类id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpLoadProjectDetail({ projectId }) {
  return http
    .post("arrange/project/manage/project/out/detail", { id: projectId })
    .then(({ data }) => data);
}
/**
 * 保存变更所属项目
 * @param {Object} data
 * @param {String} data.categoryId -- 门类id
 * @param {String} data.objectType -- 档案类型
 * @param {String} data.projectId -- 项目id
 * @param {Array} data.selection -- 选中的数据
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpSaveArchiveChangeProject({
  categoryId,
  selection,
  objectType,
  projectId,
}) {
  return http
    .post("arrange/repo/project/put", {
      id: categoryId,
      dataids: selection,
      objecttype: objectType,
      projectid: projectId,
    })
    .then(({ data }) => data);
}
/**
 * 同步某个门类的保存库到利用库接口
 * @param {Object} data
 * @param {String} data.categoryId -- 门类id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function httpSyncStorageData({ categoryId }) {
  return http
    .post("arrange/using/synchronization/using", {
      id: categoryId,
    })
    .then(({ data }) => data);
}
