export default {
  addAnnualReport: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/statistic/annualReportStatistic/dialog/AddAnnualReport"
      ),
  },
  addSummary: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/statistic/annualReportStatistic/dialog/AddSummary"
      ),
  },
  reportDetail: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/statistic/annualReportStatistic/dialog/ReportDetail"
      ),
  },
  reportDialog: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/statistic/annualReportStatistic/dialog/ReportDialog"
      ),
  },
  reportTemplate: {
    width: "m",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/statistic/annualReportStatistic/dialog/ReportTemplate"
      ),
  },
  reportCompare: {
    width: "l",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/statistic/annualReportStatistic/dialog/ReportCompare"
      ),
  },
};
