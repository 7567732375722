<template>
  <div class="file-gallery">
    <slot name="header"></slot>
    <simple-data-list :list="list">
      <template #listItem="{ item }">
        <div
          class="file-gallery-item"
          :class="{ 'file-gallery-item-error': item.error }"
        >
          <div class="file-gallery-item-main" @click="onItemClick(item)">
            <div class="file-gallery-icon">
              <file-icon :ext="item.ext" />
            </div>
            <div
              class="file-gallery-filename"
              :title="`${item.name}.${item.ext}`"
            >
              {{ item.name }}.{{ item.ext }}
            </div>
            <div v-if="item.size" class="file-gallery-filesize">
              {{ fileSize(item.size) }}
            </div>
          </div>
          <div
            class="file-gallery-op"
            :class="{ 'file-gallery-op-show': item.id === currentId }"
          >
            <div
              v-if="!item.error"
              class="file-gallery-op-tool"
              :title="$t('actions.preview')"
              @click="onPreview(item)"
            >
              <eye-outlined />
            </div>
            <div
              v-if="canDownload(item)"
              class="file-gallery-op-tool"
              :title="$t('actions.download')"
              @click="onDownload(item)"
            >
              <download-outlined />
            </div>
            <a-popconfirm
              v-if="canRemove(item)"
              :title="deleteHint"
              :ok-text="$t('actions.yes')"
              :cancel-text="$t('actions.no')"
              @confirm="$emit('remove', item)"
              @visible-change="onConfirmClose"
            >
              <div
                class="file-gallery-op-tool"
                :title="$t('actions.delete')"
                @click="beforeRemove(item)"
              >
                <delete-outlined />
              </div>
            </a-popconfirm>
          </div>
          <div v-if="item.loading" class="file-gallery-progress">
            <a-progress :percent="item.progress" size="small" />
          </div>
        </div>
      </template>
    </simple-data-list>
  </div>
</template>

<script>
import { inject, ref, toRefs } from "vue";
import { DIALOGS } from "@/conf/symbols";
import { useGallery } from "@/components/common/shared/fileCompInternal";
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";
import FileIcon from "@/components/common/file/FileIcon";
import SimpleDataList from "@/components/common/display/SimpleDataList";

export default {
  name: "FileGallery",
  components: {
    SimpleDataList,
    FileIcon,
    EyeOutlined,
    DeleteOutlined,
    DownloadOutlined,
  },
  props: {
    list: { type: Array, default: () => [] },
    removable: { type: [Boolean, Function] },
    downloadable: { type: [Boolean, Function] },
    customEvents: { type: Object, default: () => ({}) },
  },
  emits: ["remove", "download", "preview"],
  setup(props, { emit }) {
    const propRefs = toRefs(props);
    const { deleteHint, fileSize, canRemove, canDownload, onDownload } =
      useGallery("file", propRefs, { emit });
    const { customEvents } = propRefs;
    const dialogs = inject(DIALOGS, null);
    const currentId = ref();
    const beforeRemove = (item) => {
      currentId.value = item.id;
    };
    const onConfirmClose = (v) => {
      if (!v) currentId.value = undefined;
    };
    const onPreview = (file) => {
      if (customEvents.value?.preview === true) {
        emit("preview", file);
      } else {
        dialogs?.openDialog?.("common.attachment.preview", { file });
      }
    };
    const onItemClick = (file) => {
      if (!file.error) onPreview(file);
    };
    return {
      deleteHint,
      currentId,
      canRemove,
      canDownload,
      fileSize,
      onConfirmClose,
      beforeRemove,
      onPreview,
      onDownload,
      onItemClick,
    };
  },
};
</script>

<style scoped>
.file-gallery {
}
.file-gallery-item {
  display: grid;
  grid-template-areas: "main op" "progress progress";
  grid-template-columns: 1fr 90px;
  flex: 1;
}
.file-gallery-item-main {
  grid-area: main;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 32px;
  cursor: pointer;
}
.file-gallery-icon {
  padding: 0 8px;
  font-size: 16px;
  color: var(--rmx-icon-1);
}
.file-gallery-filename {
  font-size: 14px;
  color: var(--rmx-text-1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.file-gallery-filesize {
  font-size: 12px;
  color: var(--rmx-text-3);
  margin-left: 12px;
  white-space: nowrap;
}
.file-gallery-op {
  grid-area: op;
  display: none;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
  gap: 8px;
}
.file-gallery-op-tool {
  cursor: pointer;
  color: var(--rmx-icon-2);
}
.file-gallery-progress {
  grid-area: progress;
  padding: 0 32px;
}
.file-gallery-item:hover .file-gallery-op,
.file-gallery-op-show.file-gallery-op {
  display: flex;
}
.file-gallery-item:hover .file-gallery-filename {
  color: var(--rmx-primary-color);
}
.file-gallery-op-tool:hover {
  color: var(--rmx-primary-color);
}
.file-gallery-item-error .file-gallery-filename,
.file-gallery-item-error .file-gallery-icon {
  color: var(--rmx-error-color);
}
</style>
