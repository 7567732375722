import axios from "axios";
import { BASE_URL, FILE_CHUNK_SIZE } from "@/conf";
import { DEFAULT_UPLOAD_URL } from "@/conf/constants";
import { setCache } from "@/api/common";
import store from "@/store";
import { http } from "@/util/http";
import { getQueryStr } from "@/util/strUtil";
import { createHash } from "@/util/cryptoUtil";
import { showLoading } from "@/util/dialog";
import { i18n } from "@/conf/lang";

const $t = (k, a) => i18n.global.t(k, a);

const XAT = "x-access-token";
const DETECT_FILE_GROUP = [
  {
    type: "image",
    group: "image",
    ext: ["gif", "bmp", "jpg", "jpeg", "png", "svg"],
  },
  {
    type: "audio",
    group: "audio",
    ext: ["mp3", "wav", "flac", "aac", "wma", "ogg"],
  },
  {
    type: "video",
    group: "video",
    ext: ["mp4", "avi", "wmv", "flv", "mkv", "mov", "mpg"],
  },
  { type: "html", group: "html", ext: ["html", "htm", "mht"] },
  {
    type: "excel",
    group: "formatted",
    ext: ["csv", "xls", "xlsx", "ods", "et"],
  },
  {
    type: "word",
    group: "formatted",
    ext: ["doc", "docx", "rtf", "odt", "wps"],
  },
  {
    type: "ppt",
    group: "formatted",
    ext: ["ppt", "pptx", "pps", "odp", "wpp"],
  },
  { type: "text", group: "formatted", ext: ["txt", "text"] },
  { type: "pdf", group: "formatted", ext: ["pdf"] },
  { type: "ofd", group: "formatted", ext: ["ofd"] },
  { type: "compressed", group: "compressed", ext: ["zip", "rar", "7z", "gz"] },
];

export function getFileUrl(url, data) {
  if (!url) return "";
  const token = store.state?.accessToken;
  const getParam = data ? { ...data, [XAT]: token } : { [XAT]: token };
  const query = `${getQueryStr(getParam)}`;
  if (url.startsWith("http://") || url.startsWith("https://")) {
    if (url.includes("?")) {
      return `${url}&${query}`;
    }
    return `${url}?${query}`;
  }
  if (url.includes("?")) return `${BASE_URL}${url}&${query}`;
  return `${BASE_URL}${url}?${query}`;
}

export function getFileUrlFromRelative(url, addToken = false) {
  if (!url) return "";
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  }
  if (url[0] === "/") {
    url = url.substring(1);
  }
  if (addToken) {
    const TOKEN_EXP = new RegExp(`${XAT}=[^&]*`, "ig");
    const token = `${XAT}=${store.state?.accessToken}`;
    if (TOKEN_EXP.exec(url)) {
      url = url.replace(TOKEN_EXP, token);
    } else {
      url = url.includes("?") ? `${url}&${token}` : `${url}?${token}`;
    }
  }
  return BASE_URL + url;
}

export function getRelativeFileUrl(url, removeToken = false) {
  if (!url) return "";
  if (url.startsWith(BASE_URL)) {
    url = url.substring(BASE_URL.length);
    if (removeToken) {
      const TOKEN_EXP = new RegExp(`${XAT}=[^&]*`, "ig");
      url = url.replace(TOKEN_EXP, "").replace("?&", "?").replace("&&", "&");
      if (url.endsWith("?")) url = url.substring(0, url.length - 1);
    }
  }
  return url;
}

export function getFileInfo(filename) {
  const tmp = filename.split(".");
  const ext = tmp.length > 1 ? tmp[tmp.length - 1].toLowerCase() : "";
  const name = filename.substring(0, filename.length - ext.length - 1);
  return { name, ext };
}

export function detectFileType(ext = "") {
  const t = (ext || "").toLowerCase();
  const g = DETECT_FILE_GROUP.find((x) => x.ext.includes(t));
  return g
    ? { group: g.group, type: g.type }
    : { group: "unknown", type: "unknown" };
}

export function shortenFilename(filename = "", maxlength = 15) {
  if (!filename || filename.length <= maxlength) {
    return filename;
  }
  const index = filename.lastIndexOf(".");
  const ext = filename.slice(index);
  const name = filename.slice(0, index);
  const prefix = Math.floor((maxlength - ext.length) / 2);
  const suffix = maxlength - ext.length - prefix;
  return name.slice(0, prefix) + "..." + name.slice(-suffix) + ext;
}

export function httpDownload(url, data, filename, method = "POST") {
  const conf = {
    responseType: "blob",
  };
  let loading = null;
  const showName = filename ? shortenFilename(filename) : $t("things.file");
  const callback = (res) => {
    let disposition = res.headers["content-disposition"];
    if (disposition && !filename && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
        filename = decodeURIComponent(filename);
      }
    }
    const fileUrl = URL.createObjectURL(new Blob([res.data]));
    const fileLink = document.createElement("a");
    fileLink.href = fileUrl;
    fileLink.setAttribute("download", (filename || "").trim());
    document.body.append(fileLink);
    fileLink.click();
    fileLink.remove();
    loading?.finish($t("hint.file.downloaded", [showName]));
    return Promise.resolve();
  };
  loading = showLoading(`${$t("hint.file.downloading", [showName])}...`);
  if (method === "GET") {
    return http.get(getFileUrl(url, data), conf).then(callback);
  }
  return http.post(url, data, conf).then(callback);
}

export function downloadFileWith(file) {
  let filename = "";
  if (file.name && file.ext) {
    filename = `${file.name}.${file.ext}`;
  }
  httpDownload(
    getFileUrlFromRelative(file.downloadUrl || file.url),
    {},
    filename,
    "GET"
  ).then(() => {});
}

const chunkRange = (file, current) => {
  const start = current * FILE_CHUNK_SIZE;
  const tmpEnd = start + FILE_CHUNK_SIZE;
  const end = tmpEnd >= file.size ? file.size : tmpEnd;
  return { start, end };
};

export function readFileChunk(
  file,
  { onProgress, onFinished, start = 0 } = {}
) {
  if (file.size === 0) {
    onFinished?.();
    return;
  }
  let current = start;
  const chunks = Math.ceil(file.size / FILE_CHUNK_SIZE);
  const reader = new FileReader();
  reader.onload = (evt) => {
    const res = onProgress?.({
      data: evt.target.result,
      progress: Math.floor((current * 1000) / chunks) / 10,
    });
    if (res && typeof res.then === "function") {
      res.then(() => moveNext());
    } else {
      moveNext();
    }
  };
  const moveNext = () => {
    current++;
    if (current < chunks) {
      loadNext();
    } else {
      onFinished?.();
    }
  };
  const loadNext = () => {
    const { start, end } = chunkRange(file, current);
    reader.readAsArrayBuffer(file.slice(start, end));
  };
  loadNext();
}

export function sliceFile(
  file,
  { onStarted, onProgress, onFinished, start = 0 }
) {
  let stopped = false;
  let finished = false;
  const stop = () => {
    if (finished) return;
    stopped = true;
  };
  onStarted?.({ stop });
  if (file.size === 0) {
    onProgress?.({ data: file, current: 0, total: 1, progress: 100 }).then(
      () => {
        finished = true;
        onFinished?.();
      }
    );
    return;
  }
  let current = start;
  const total = Math.ceil(file.size / FILE_CHUNK_SIZE);
  const loadNext = () => {
    if (stopped) return;
    const { start, end } = chunkRange(file, current);
    onProgress?.({
      data: file.slice(start, end),
      current,
      total,
      progress: Math.floor((current * 1000) / total) / 10,
    }).then(() => {
      current++;
      if (current < total) {
        loadNext();
      } else {
        finished = true;
        onFinished?.();
      }
    });
  };
  loadNext();
}

export function getFileHash(file, { onProgress } = {}) {
  const insMd5 = createHash();
  const insSha1 = createHash("sha1");
  return new Promise((resolve) => {
    readFileChunk(file, {
      onProgress: ({ data, progress }) =>
        new Promise((resolveProgress) => {
          insMd5.update(data);
          insSha1.update(data);
          onProgress?.({ progress });
          resolveProgress();
        }),
      onFinished() {
        const md5 = insMd5.digest();
        const sha1 = insSha1.digest();
        const hash = sha1 + md5.substring(8, 24);
        onProgress?.({ progress: 100 });
        resolve({ hash, md5, sha1 });
      },
    });
  });
}

export function uploadFileChunks({
  uploadUrl,
  file,
  index,
  slice,
  data = {},
  onStarted,
  onProgress,
} = {}) {
  let stopSlice;
  let stopUpload;
  let finished = false;
  const cancelToken = new axios.CancelToken((c) => {
    stopUpload = c;
  });
  const stop = () => {
    if (finished) return;
    stopSlice?.();
    stopUpload?.();
  };
  const handleErr = (e) => {
    if (e && e?.code === "ERR_CANCELED") {
      return;
    }
    return e;
  };
  const onUploadProgress = ({ loaded, total }) => {
    const progress = ((loaded * 100) / total).toFixed(1);
    onProgress?.({ progress, type: "uploading" });
  };
  onStarted?.({ stop });
  return new Promise((resolve) => {
    const form = new FormData();
    Object.keys(data).forEach((x) => {
      if (data[x] !== undefined) form.set(x, data[x]);
    });
    if (slice) {
      let fileInfo;
      sliceFile(file, {
        start: index,
        onStarted: ({ stop }) => {
          stopSlice = stop;
        },
        onProgress: ({ data, progress, current, total }) => {
          const filename =
            current + 1 === total ? file.name : `${file.name}.${current}.part`;
          form.set("file", data, filename);
          form.set("current", current);
          form.set("total", total);
          return http.post(uploadUrl, form, { cancelToken }).then((res) => {
            onProgress?.({ progress, type: "uploading" });
            fileInfo = res.data;
          }, handleErr);
        },
        onFinished: () => {
          finished = true;
          resolve(fileInfo);
        },
      });
    } else {
      form.set("file", file, file.name);
      http
        .post(uploadUrl, form, { cancelToken, onUploadProgress })
        .then((res) => {
          finished = true;
          resolve(res.data);
        }, handleErr);
    }
  });
}

export function uploadFile({
  uploadUrl = DEFAULT_UPLOAD_URL,
  checkUrl,
  file,
  data = {},
  slice = false,
  onStarted,
  onProgress,
} = {}) {
  return new Promise((resolve) => {
    const doUpload = (index, hash) => {
      uploadFileChunks({
        uploadUrl,
        file,
        onProgress,
        onStarted,
        index,
        slice,
        data: { ...data, hash },
      }).then((fileData = {}) => {
        if (hash && !fileData.hash) fileData.hash = hash;
        resolve(fileData);
      });
    };
    if (checkUrl) {
      getFileHash(file, {
        onProgress: (pe) => {
          onProgress?.({ ...pe, type: "hashing" });
        },
      }).then(({ hash }) => {
        http.post(checkUrl, { ...data, hash }).then((checkRes) => {
          const checkData = checkRes.data;
          if (!checkData) return;
          const status = checkData.status;
          if (status === "uploaded") {
            const fileData = checkData.file || {};
            fileData.isShared = true;
            if (!fileData.hash) fileData.hash = hash;
            resolve(fileData);
          } else {
            const index = status === "uploading" ? checkData.index : 0;
            doUpload(index, hash);
          }
        });
      });
    } else {
      doUpload();
    }
  });
}

export function mimeCheck(accept, file) {
  if (!accept || !file) return false;
  const accepts = accept.split(",").map((x) => (x || "").trim());
  if (accepts.includes("*/*")) return true;
  const expArr = [];
  const extArr = [];
  accepts.forEach((x) => {
    if (!x) return;
    if (x.includes("/")) {
      expArr.push(new RegExp(x.replace("*", "[^\\/,]+")));
    } else if (x.includes(".")) {
      extArr.push(x);
    }
  });
  let r = false;
  if (extArr.length > 0) {
    const expExp = new RegExp(
      `(${extArr.map((x) => `\\${x}`).join("|")})$`,
      "i"
    );
    r = expExp.test(file.name);
  }
  return r || expArr.some((exp) => exp.test(file.type));
}

export function downloadStream(url, data, filename) {
  return httpDownload(url, data, filename, "POST");
}

export function downloadWithCid(url, cid, method = "GET") {
  return httpDownload(url, { cid }, "", method);
}

export function previewWithCid(url, cid, method = "GET") {
  if (method === "GET") {
    window.open(getFileUrl(url, { cid }));
  } else {
    return httpDownload(url, { cid });
  }
}

export function downloadRawFile(type, id, method = "GET") {
  return httpDownload("common/raw_file", { type, id }, "", method);
}

export function printReport(
  { ids, where, type, reportId, extra } = {},
  method = "GET"
) {
  return setCache({ ids, where, type, reportId, extra }).then((res) => {
    if (res.code === 0) {
      return previewWithCid("common/print_report", res.data, method);
    }
  });
}

export function exportData({ ids, where, type, extra } = {}, method = "GET") {
  return setCache({ ids, where, type, extra }).then((res) => {
    if (res.code === 0) {
      return downloadWithCid("common/export_data", res.data, method);
    }
  });
}

export function downloadZip({ ids, where, type, extra } = {}, method = "GET") {
  return setCache({ ids, where, type, extra }).then((res) => {
    if (res.code === 0) {
      return downloadWithCid("common/download_zip", res.data, method);
    }
  });
}

export function viewFile({ id, tableId }) {
  window.open(`#/manage/arch_viewer?tableId=${tableId}&id=${id}`, "_blank");
}
