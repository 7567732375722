import { http } from "@/util/http";
import { compatBool } from "@/util/dataUtil";

export function removeImage({ id, type }) {
  return http.post("common/remove_image", { id, type });
}

export function removeFile({ id, type }) {
  return http.post("common/remove_file", { id, type });
}

export function sendWsMessage({ type, channel, content } = {}) {
  return http.post("common/send_message", { type, channel, content });
}

export function nextId({ size } = {}) {
  return http.post("common/next_id", { size });
}

export function reportList({ type }) {
  return http.post("common/report_list", { type });
}

export function usersList({ deptId, roleId, search } = {}) {
  return http.post("common/users", { deptId, roleId, search });
}

export function systemConfig() {
  return http.post("common/system_config", {});
}

export function setCache(content) {
  return http.post("common/set_cache", content);
}

export function getCache({ id }) {
  return http.post("common/get_cache", { id });
}

export function personalData() {
  return http.post("common/personal", {});
}

/**
 * 根据当前登录人获取全宗列表[]
 *
 * @param {object} param - 参数对象.
 * @param {Boolean} param.isAuth - 是否授权.
 * @returns {Array} 全宗数据集合.
 */
export function httpLoadFondsList({ isAuth }) {
  return http
    .post("common/user/fonds/list", { isauth: isAuth })
    .then(({ data }) => data.list);
}

/**
 * 获取所有单位信息
 * @returns {Array} 单位信息的一维度树结构.
 */
export function httpGetCompanyList({ isTotal = false, where = "back" }) {
  return http
    .post("common/org/all", { totalorg: isTotal, where })
    .then(({ data }) => data.list);
}

/**
 * 获取当前公司的部门树以及角色列表
 * @param {object} param - 参数对象.
 * @param {string} param.companyId - 公司Id.
 * @returns {Object} 相关信息.
 */
export function httpGetDeptAndRoleTreeData({ companyId }) {
  return http
    .post("common/popup/org/role/users", { id: companyId })
    .then(({ data }) => data);
}

/**
 * 获取指定部门的人员列表
 * @param {object} param - 参数对象.
 * @param {string} param.deptId - 部门Id.
 * @returns {Object} 相关信息.
 */
export function httpGetDeptUser({ deptId }) {
  return http
    .post("common/popup/org/users", { id: deptId })
    .then(({ data }) => data.list);
}

/**
 * [1-3]获取角色的人员列表 TODO:doc
 * @param {object} param - 参数对象.
 * @param {string} param.deptId - 部门Id.
 * @returns {Object} 相关信息.
 */
export function httpGetRoleUser({ companyId, roleId }) {
  return http
    .post("common/popup/role/users", { comid: companyId, roleid: roleId })
    .then(({ data }) => data.list);
}

/**
 * 按用户名或账号查找人员
 * @param {object} param - 参数对象.
 * @param {string} param.companyId - 公司Id
 * @param {string} param.searchWord - 查询单词.
 * @returns {Object} 相关信息.
 */
export function httpSearchUser({ companyId, searchWord }) {
  return http
    .post("common/popup/search/users", { id: companyId, param: searchWord })
    .then(({ data }) => data.list);
}

/**
 * 按用户岗位ID组查找人员
 * @param {object} param - 参数对象.
 * @param {Array} param.userIdList - 查询单词.
 * @returns {Object} 相关信息.
 */
export function httpLoadUserInfo({ userIdList }) {
  return http
    .post("common/popup/search/stationdetail", userIdList)
    .then(({ data }) => data.list);
}

/**
 * 获取当前单位下某一类型报表
 * @param {object} param - 参数对象.
 * @param {String} param.model - 报表类型.
 * @param {Number} param.objectType - 文件类型 1项目，2案卷，3文件，4盒.
 * @param {String} param.tableId - 报表所针对的表 档案表统一用 archive.
 * @param {String} param.action - 报表类型的取数方法（个别地方要用）
 * @param {String} param.archiveid - 门类id，没有model的时候后端根据门类id自己获取model
 * @returns {Object} 相关信息.
 */
export function httpGetReportList({
  model,
  objectType,
  tableId,
  action,
  archiveid,
}) {
  return http
    .post("common/report/all", {
      model,
      action,
      objecttype: objectType,
      tableid: tableId,
      archiveid,
    })
    .then(({ data }) => data.list);
}

/**
 * 根据当前登录人获取单位树接口（多级）
 * @param data  data中存在{Boolean} [isAll] -  true 返回全部的单位信息 false 则会过滤掉馆单位数据
 *              data为{} 返回根据当前登录人获取单位，返回当前登录用户所在公司的所有子公司包含自己
 *              data为{id：String} 返回id公司的所有子公司包含自己
 *
 */
export function httpInitComTree(data) {
  return http
    .post(`common/user/org/tree`, data)
    .then(({ data }) => data.list)
    .then((list) =>
      list.map((x) => ({
        key: x.id,
        pId: x.fatherid,
        title: x.name,
        ...x,
      }))
    );
}

/**
 * 获取全宗的归档部门列表
 * @param {String} id - 全宗id
 */
export function httpLoadArchDepList(id) {
  return http.post(`common/archdep/list`, { id: id });
}

/**
 * 获取全部单位树
 */
export function httpOrgTreeAll() {
  return http.post(`common/org/tree/all`, {});
}

/**
 * 获取单位的全部全宗
 */
export function httpOrgFondsAll(comId) {
  return http.post(`common/org/fonds/all`, { id: comId });
}

/**
 * 获取问题分类配置详情
 */
export function httpClassifyConfig() {
  return http.post(`common/system/extsetting/classfyconfig/content`, {});
}

/**
 * 获取元数据模板集合
 */
export function httpLoadMetadata() {
  return http.post("common/archtype/category", {});
}

/**
 * 获取对象元数据字段列表接口
 * @param {object} data - 参数对象.
 * @param {String} data.archtypeid - 门类id.
 * @param {String} data.colstype - 要展示的列类型 searchbox：检索框显示 datacolumn.数据列显示 batchmodifycols:批量更新数据列显示
 * @param {Number} data.objecttype - 文件类型 1项目，2案卷，3文件，4盒.
 * @returns {Object} 相关信息.
 */
export function httpColumns(data) {
  return http.post("common/columns", data);
}

/**
 * 获取案卷元数据接口
 * @param {object} data - 参数对象.
 * @param {String} data.archtypeid - 门类id.
 * @param {String} data.colstype - 要展示的列类型 searchbox：检索框显示 datacolumn.数据列显示 batchmodifycols:批量更新数据列显示
 * @param {Number} data.objecttype - 文件类型 1项目，2案卷，3文件，4盒.
 * @returns {Object} 相关信息.
 */
export function httpVolCols(data) {
  return http.post("common/arrange/repodoc/volcols", data);
}

/**
 * 获取档案(案卷 盒 项目)列表接口
 * @param {object} param - 参数对象.
 * @param {object} query - 负载条件
 * @param {number} [param.isAuth] - 是否授权.
 * @param {String} [param.fondsId] - 全宗Id
 * @param {String} [param.searchField] - 全宗Id
 * searchField
 * @returns {Object} 相关信息.
 */
export function httpLoadArchTree({ fondsId, searchField, isAuth }, query) {
  return http
    .post("common/arch/component/tree", {
      fondsid: fondsId,
      searchField,
      isauth: compatBool(isAuth),
      type: 1,
      ...query,
    })
    .then(({ data }) => data);
}
/**
 * 获取档案(案卷 盒 项目)列表接口
 * @param {object} param - 参数对象.
 * @param {object} [query] - 负载条件
 * @param {number} [param.isAuth] - 是否授权.
 * @param {String} [param.fondsId] - 全宗Id
 * @returns {Object} 相关信息.
 */
export function httpLoadArchTreeFuzzy(
  { fondsId, isAuth, status, searchField },
  query = {}
) {
  return http
    .post("/arrange/using/treevague", {
      searchField,
      fondsid: fondsId,
      isauth: compatBool(isAuth),
      status: +status === 30 ? 1 : status,
      paramscenetype: +status === 30,
      ...query,
    })
    .then(({ data }) => data);
}
/**
 * 获取全宗门类列表接口 -- 组合检索使用
 * @param {object} param - 参数对象.
 * @param {Boolean} param.isAuth - 是否授权.
 * @param {object} [query] - 负载条件
 * @returns {Object} 相关信息.
 */
export function httpLoadArchTreeCombine({ isAuth }, query = {}) {
  return http
    .post("common/arch/component/fronds/tree", {
      isauth: compatBool(isAuth), //是否过滤权限
      ...query,
    })
    .then(({ data }) => data);
}

/**
 * 当前可选择状态
 * @returns {Object} 相关信息.
 */
export function httpLoadArchStatus() {
  return http
    .post("common/arch/component/archtypestatus", {})
    .then(({ data }) => data.list);
}

/**
 * 获取档案(案卷 盒 项目)列表接口
 * @param {object} param - 参数对象.
 * @param {String} param.categoryId - 门类ID.
 * @param {String} param.objectType - 档案类型.
 * @param {Number} [param.page] - 页码.
 * @param {string} [param.searchField] - 查询单词
 * @param {Object} [param.groupSearch] - 查询条件
 * @param {Number} param.status - 档案状态
 * @param {Number} param.size - 分页个数.
 * @param {object} param.rest - 剩余参数.
 * @returns {Object } 相关信息.
 */
export function httpLoadArchList({
  categoryId,
  objectType,
  status,
  searchField,
  groupSearch,
  page = 1,
  size,
  ...rest
}) {
  return http
    .post("arrange/using/component/archlist", {
      archtypeid: categoryId,
      objecttype: objectType,
      ...rest,
      page,
      searchField,
      groupsearch: groupSearch,
      limit: size,
      status: +status === 30 ? 1 : status,
      paramscenetype: +status === 30,
    })
    .then(({ data }) => data);
}

/**
 * 获取档案(案卷 盒 项目)列表接口
 * @param {object} param - 参数对象.
 * @param {String} param.categoryId - 门类ID.
 * @param {String} param.objectType - 档案类型.
 * @param {Number} param.status - 档案状态
 * @param {String} param.dataIds - 数据id组.
 * @returns {Object } 相关信息.
 */
export function httpTransArchList({
  categoryId,
  objectType,
  status,
  dataIds,
  business,
}) {
  return http
    .post("common/arch/component/archinfo", {
      business,
      id: categoryId,
      objecttype: objectType,
      status,
      dataids: dataIds,
    })
    .then(({ data }) => data);
}

/**
 * 获取所有流程
 * @param {object} param - 参数对象.
 * @param {string} param.funType - 流程类型.
 * @param {string} [param.archTypeId] - 门类Id
 * @param {object} param.model -条目的的元数据分组
 * @param {Boolean} param.security - 是否涉密
 * @returns {Object} 相关信息.
 */
export function httpGetFlow({ funType, archTypeId, model, security }) {
  return http
    .post("common/activity/work/flow/list", {
      funType,
      archTypeId,
      model,
      security,
    })
    .then(({ data }) => data.list);
}

/**
 * 获取环节
 * @param {object} param - 参数对象.
 * @param {String} param.id - 业务单id.
 * @param {String} param.funType - 流程类型.
 * @param {Number} param.workFlowId - 流程id.
 * @returns {Object} 相关信息.
 */
export function httpGetNode({ id, funType, workFlowId }) {
  return http
    .post("common/activity/node/list", { id, funType, workFlowId })
    .then(({ data }) => data.list);
}

/**
 * 获取审批人
 * @param {object} param - 参数对象.
 * @param {String} param.id - 业务单id.
 * @param {String} param.funType - 流程类型.
 * @param {String} param.workFlowId - 流程id.
 * @param {String} param.nodeInfoId - 环节id.
 * @returns {Object} 相关信息.
 */
export function httpGetUser({ id, funType, workFlowId, nodeInfoId }) {
  return http
    .post("common/activity/user/list", { id, funType, workFlowId, nodeInfoId })
    .then(({ data }) => data);
}

/**
 * 提交审批流程
 * @param {object} param - 参数对象.
 * @param {String} param.id - 业务单id.
 * @param {String} param.funType - 流程类型.
 * @param {String} param.workFlowId - 流程id.
 * @param {String} param.nodeInfoId - 环节id.
 * @param {String} param.userId - 用户id
 * @param {String} param.roleId - 部门id.
 * @param {String} param.deptId - 角色id.
 * @returns {Object} 相关信息.
 */
export function httpSubmitAudit({
  id,
  funType,
  workFlowId,
  nodeInfoId,
  userId,
  roleId,
  deptId,
}) {
  return http.post("common/activity/submit", {
    id,
    funType,
    workFlowId,
    nodeId: nodeInfoId,
    userId,
    deptId,
    roleId,
  });
}

/**
 * 获取审批操作
 * @param {object} param - 参数对象.
 * @param {String} param.workFlowId - 流程id.
 * @param {String} param.nodeInfoId - 环节id.
 * @param {String} param.processInstanceId - 用户id
 * @param {String} param.portalWorkId - 部门id.
 * @returns {Object} 相关信息.
 */
export function httpGetActionType({
  processInstanceId,
  portalWorkId,
  workFlowId,
  nodeInfoId,
}) {
  return http
    .post("common/activity/type/list", {
      workFlowId, //流程id
      nodeId: nodeInfoId, //节点id
      processInstanceId, //流程实例id
      portalWorkId, //待办id
      nodeInfoId, //
    })
    .then(({ data }) => data);
}

/**
 * 获取审批操作详情
 * @param {object} param - 参数对象.
 * @param {String} param.id - 业务单id.
 * @param {String} param.funType - 流程类型.
 * @param {String} param.type - 1 同意类;0 不同意类;2 其他类
 * @returns {Object} 相关信息.
 */
export function httpGetActionDetail({ funType, id, type }) {
  return http
    .post("common/activity/action/list", {
      type, //1 同意类;0 不同意类;2 其他类
      funType, //流程类型
      id, //业务单id
    })
    .then(({ data }) => data.list);
}

/**
 * 获取审批操作详情
 * @param {object} param - 参数对象.
 * @param {String} param.id - 业务单id.
 * @param {String} param.funType - 流程类型.
 * @param {String} param.actionCode - 处理方式
 * @param {String} param.nodeInfoId - 环节id.
 * @param {String} param.userId - 用户id
 * @param {String} param.roleId - 部门id.
 * @param {String} param.deptId - 角色id.
 * @param {String} param.memo - 备注.
 * @returns {Object} 相关信息.
 */
export function httpSubmitAction({
  funType,
  id,
  actionCode,
  userId,
  deptId,
  roleId,
  nodeInfoId,
  memo,
}) {
  return http.post("common/activity/operation", {
    funType, //流程类型
    actionCode, //处理方式
    id, //业务单id
    nodeId: nodeInfoId,
    userId, //用户id
    deptId, //部门id
    roleId, //角色id
    memo, //备注
  });
}

/**
 * 获取流程日志
 * @param {object} param - 参数对象.
 * @param {String} param.processInstanceId - 业务单id.
 * @returns {Object} 相关信息.
 */
export function httpGetFlowLog({ processInstanceId }) {
  return http
    .post("common/activity/log/list", {
      processInstanceId, //流程实例id
      isApproval: 1, //是否为审批页面。非审批页面则会按时间倒序排列
    })
    .then(({ data }) => data.list);
}

/**
 * 修改密码-获取加密公钥
 */
export function httpLoadPublicKey() {
  return http.post("/system/login/sm2/publickey", {});
}

/**
 * 修改密码-获取密码校验规则
 */
export function httpLoadEditPswRule() {
  return http.post("/common/user/pwdrules", {});
}

/**
 * 修改密码
 * @param {object} param - 参数对象.
 * @param {String} param.oldPassword - 加密后的旧密码.
 * @param {String} param.newPassword - 加密后的新密码.
 */
export function httpEditPsw(param) {
  return http
    .post("common/user/password/update", param)
    .then(({ code, data, message = {} }) => {
      if (code === 0) {
        return Promise.resolve(data);
      }
      return Promise.reject(message);
    });
}

/**
 * 切换身份
 *
 * @param {object} param - 参数对象.
 * @param {String} param.back - 政务网从前台切入进后台的时候会传入此值.
 * @param {String} param.comAttrId - 单位属性id，若传入此参数，其他入参不会在传.
 * @param {String} param.comid - 单位id.
 * @param {String} param.front - 政务网下前台左上角切换单位，值为front时启用，其余忽略.
 * @param {String} param.isVirtual - 是否是虚拟单位  0:不是虚拟单位 1：是虚拟单位.
 * @param {String} param.roleid - 角色id.
 * @param {String} param.stationid - 岗位id.
 *
 */
export function httpSwitchRole(param) {
  return http.post("common/user/role/switch", param);
}

/**
 * 档案著录默认布局
 *
 * @param {object} data - 参数对象.
 * @param {String} data.archtypeid - 门类id
 * @param {String} data.objecttype - 档案类型
 */
export function httpGetDefaultLayout(data) {
  return http.post("common/fields/getdefaultlayout", data);
}

/**
 * 获取档案详情
 *
 * @param {object} data - 参数对象.
 * @param {String} data.id - 门类id
 * @param {String} data.objecttype - 档案类型
 * @param {String} data.dataid - 档案id
 * @param {String} data.fillingdepartment - 归档部门
 * @param {Number} data.from - 1手工著录，2条目保存 3插录 4追加 5查看
 * @param {String} data.initData - 初始化数据
 * @param {String} data.superdataid - 父档案id
 * @param {String} data.superobjecttype - 父档案类型
 * @param {Number} data.opt - 0 待整理 7已整理 1已保存
 */
export function httpArchiveLoad(data) {
  return http.post("common/archive/load", data);
}

/**
 * 获取档案详情
 *
 * @param {object} param - 参数对象.
 * @param {String} param.categoryId - 门类ID.
 * @param {String} param.objectType - 档案对象.
 */
export function httpCategoryMetaData({ categoryId, objectType }) {
  return http
    .post("/common/columns", {
      archtypeid: categoryId,
      objecttype: objectType,
      colstype: "searchbox",
    })
    .then(({ data }) => data);
}

/**
 * 获取待办详情
 * @param {String} id - 待办id
 */
export function httpPortalWorkDetail(id) {
  return http.post("common/personal/out/portal/work/getOne", { id: id });
}
/**
 * 获取消息详情
 * @param {String} id - 消息id
 */
export function httpMessageDetail(id) {
  return http.post("common/message/detail", { id: id });
}
/**
 * 获取允许上传文件大小
 *
 */
export function httpGetFileSizeLimit() {
  return http.post("common/file/size");
}
/**
 * 获取转换方式（pdf、ofd-友虹、ofd-数科、ofd-福昕）
 */
export function httpLoadPdfOrOfd() {
  return http.post("common/file/pdforofd");
}

/**
 * 获取人员详细信息
 */
export function httpLoadUserDetailInfo(id) {
  return http.post("common/user/info", { id }).then(({ data }) => data);
}

/**
 * 获取当前用户角色信息
 */
export function httpLoadUserRoleInfo() {
  return http
    .post("common/personal/out/portal/work/role/info", {})
    .then(({ data }) => data);
}

/**
 * 获取当前用户拥有的档案权限
 * @param {String} archId - 参数对象.
 * @param {String} archTypeId - 门类ID.
 * @param {String} objectType - 档案对象.
 * @param {String} tableId - 档案对象.
 * @param {String} [authArchTypeId] 判断分工授权所用门类id，若为空则取archtypeid字段
 * @param {Boolean} [onlyArchType] - 为true时只判断门类权限，忽略档案个性化权限（为了适配对外接收场景）
 */
export function httpLoadFrontRoleBrowseAuth({
  archId,
  archTypeId,
  objectType,
  tableId,
  authArchTypeId,
  onlyArchType = false,
}) {
  return http
    .post("common/personal/out/portal/work/archive/auth", {
      archId,
      archTypeId,
      objectType,
      tableId,
      authArchTypeId,
      onlyArchType,
    })
    .then(({ data }) => data);
}

/**
 * 判断当前用户是否拥有对应门类分工授权
 * @param {String} archTypeId - 门类ID.
 */
export function httpLoadBackRoleAuth({ archTypeId }) {
  return http
    .post("common/personal/out/portal/work/archive/type/auth", { archTypeId })
    .then(({ data }) => data);
}
