import {
  DEFAULT_ACTION_DEBOUNCE,
  UI_DIRECTION,
  UI_SIZE,
} from "@/conf/constants";

export const PROPS_ASYNC_LOAD = {
  asyncLoad: { type: [Function, Object] },
  loadOnMounted: { type: Boolean },
};

export const PROPS_ABSTRACT_LIST = {
  ...PROPS_ASYNC_LOAD,
  resultList: { type: Array },

  pending: { type: Boolean },
  loading: { type: Boolean, default: false },
  emptyIcon: { type: String, default: "no_data" },
  emptyText: { type: String },
  emptySize: { type: String, default: UI_SIZE.medium },

  showOperation: { type: Boolean },
  operations: { type: Array, default: () => [] },
  operationConfig: { type: Object, default: () => ({}) },

  showSelection: { type: Boolean },
  selectType: { type: String, default: "checkbox" },
  selection: { type: Array, default: undefined },
  selectable: { type: Function },

  sortValue: { type: Object },

  showPager: { type: Boolean },
  total: { type: Number, default: 0 },
  page: { type: Number },
  size: { type: Number },
  sizeOptions: { type: Array },
  crossPage: { type: Boolean, default: false },

  showSearch: { type: Boolean, default: false },
  searchValue: { type: String },
  searchOnBlur: { type: Boolean, default: true },
  searchPlaceholder: { type: String },
  innerSearch: { type: Boolean, default: false },
  fieldKeys: { type: Array, default: () => [] },

  showFilter: { type: Boolean },
  filterFields: { type: Array, default: () => [] },
  filterValue: { type: Object },
  innerFilter: { type: Function },

  showToolIcons: { type: Boolean },
  toolIcons: { type: Array, default: () => [] },
  toolIconOptions: { type: Object, default: () => ({}) },
  toolIconChecked: { type: Array, default: () => [] },

  attachDialog: { type: [Boolean, Object] },
  attachBlock: { type: Boolean },

  height: { type: String },

  contextMenus: { type: [Array, Function] },
};

export const EMITS_ABSTRACT_LIST = [
  "toolAction",
  "menuAction",
  "iconAction",
  "pageChange",
  "search",
  "filter",
  "sort",
  "update:page",
  "update:size",
  "update:total",
  "update:loading",
  "update:resultList",
  "update:searchValue",
  "update:filterValue",
  "update:sortValue",
  "update:selection",
];

export const PROPS_ABSTRACT_TREE = {
  ...PROPS_ASYNC_LOAD,
  list: { type: Array, default: () => [] },
  autoProcess: { type: Boolean, default: true },
  disabled: { type: Boolean },
  bordered: { type: Boolean },
  keyConf: {
    type: Object,
    default: () => ({
      key: "key",
      pId: "pId",
      value: "value",
      title: "title",
    }),
  },
  value: { type: Array },
  expanded: { type: Array },
  selected: { type: Array },
  checked: { type: Array },
  checkAble: { type: Boolean },
  checkStrictly: { type: Boolean },
};

export const EMITS_ABSTRACT_TREE = [
  "nodeSelect",
  "change",
  "loaded",
  "update:expanded",
  "update:selected",
  "update:checked",
  "update:value",
];

export const PROPS_ABSTRACT_FORM = {
  name: { type: String },
  fields: { type: Array, default: () => [] },
  value: { type: Object },
  disabled: { type: Boolean },
  validators: { type: Object },

  loading: { type: Boolean },
  asyncSubmit: { type: Function },

  showAction: { type: Boolean, default: true },
  actions: { type: Array, default: () => ["submit"] },
  actionDebounce: { type: Number, default: DEFAULT_ACTION_DEBOUNCE },

  attachDialog: { type: [Boolean, Object] },
  useLocale: { type: Boolean },

  useGrid: { type: Boolean, default: false },
  defaultSpan: { type: Number },
  defaultWidth: { type: String },
  maxWidth: { type: String },
  hasPlaceholder: { type: Boolean, default: true },
  autoPlaceholder: { type: Boolean },

  size: { type: String, validator: (v) => v in UI_SIZE },
  layout: {
    type: String,
    default: UI_DIRECTION.vertical,
    validator: (v) => v in UI_DIRECTION,
  },
};

export const EMITS_ABSTRACT_FORM = [
  "submit",
  "finished",
  "validateFailed",
  "action",
  "change",
  "update:value",
  "update:loading",
];

export const PROPS_INPUT_BASE = {
  name: { type: String },
  type: { type: String },
  compat: { type: Boolean },
  disabled: { type: Boolean },
  readonly: { type: Boolean },
  required: { type: Boolean },
  placeholder: { type: [String, Array] },
  size: { type: String, validator: (v) => v in UI_SIZE },
  clearable: { type: Boolean },
  mode: { type: String },
  range: { type: Boolean },
  maxlength: { type: Number },
  input: { type: Boolean },
  text: { type: [String, Array] },
  direction: { type: String, validator: (v) => v in UI_DIRECTION },
  bordered: { type: Boolean, default: true },
  width: { type: String },
  height: { type: String },
  resize: { type: Boolean },
  raw: { type: Boolean },
  value: { type: [String, Boolean, Number, Object, Array] },
};

export const PROPS_INPUT_SELECTABLE = {
  options: { type: Array },
  optionsIcon: { type: [String, Function] },
  multiple: { type: Boolean },
  search: { type: [Function, Boolean, String] },
  selectAll: { type: Boolean },
  checkStrictly: { type: Boolean },
  maxCount: { type: Number },
};

export const PROPS_INPUT_NUMBER = {
  min: { type: Number },
  max: { type: Number },
  step: { type: Number },
};

export const PROPS_INPUT_ITEM_ALL = {
  ...PROPS_INPUT_BASE,
  ...PROPS_INPUT_SELECTABLE,
  ...PROPS_INPUT_NUMBER,
  showCount: { type: Boolean },
  showToggle: { type: Boolean },
};

export const PROPS_ATTACH_FILE = {
  url: { type: String, default: "" },
  title: { type: String, default: "" },
};

export const PROPS_UPLOAD_PARAMS = {
  uploadType: { type: String },
  uploadBusinessId: { type: [Number, String] },
  uploadParam: { type: Object, default: () => ({}) },
};

export const PROPS_UPLOADER = {
  ...PROPS_UPLOAD_PARAMS,
  uploadAction: { type: [String, Function, Object] },
  asyncRemove: { type: [Function] },
  accept: { type: String },
  value: { type: Array },
  readonly: { type: Boolean },
  disabled: { type: Boolean },
  max: { type: Number },
  maxlength: { type: Number },
  multiple: { type: Boolean },
  directory: { type: Boolean },
  raw: { type: Boolean },
};

export const EMITS_UPLOADER = ["change", "preview", "update:value"];

export const PROPS_CONTAINER = {
  height: { type: [String, Number] },
  contentPadding: { type: String },
  contentScroll: { type: Boolean },
  loading: { type: Boolean },
  loadingText: { type: String },
  pending: { type: Boolean },
  pendingText: { type: String },
  empty: { type: Boolean },
  emptyIcon: { type: String },
  emptyText: { type: String },
  emptySize: { type: String, default: UI_SIZE.medium },
};
